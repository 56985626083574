import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/expert-program/directory/directory-template-option-one.svg'
import _imports_1 from '@/assets/expert-program/directory/directory-template-option-two.svg'
import _imports_2 from '@/assets/expert-program/directory/directory-template-option-three.svg'
import _imports_3 from '@/assets/expert-program/directory/directory-template-option-four.svg'
import _imports_4 from '@/assets/expert-program/directory/directory-template-option-five.svg'


const _hoisted_1 = { class: "flex flex-col bg-white rounded-xl border border-gray-300 border-solid" }
const _hoisted_2 = { class: "flex flex-col justify-center px-6 py-5 w-full rounded-xl bg-slate-50 max-md:px-5" }
const _hoisted_3 = { class: "flex gap-10 justify-between items-center w-full" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col self-center mt-4 w-full px-4",
  ref: "collapsible"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex items-center gap-2" }, [
          _createElementVNode("div", { class: "flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 text-black font-semibold text-sm" }, " 1 "),
          _createElementVNode("h3", { class: "text-sm font-semibold text-black" }, "Select Template")
        ], -1)),
        _createElementVNode("button", {
          class: "flex justify-between items-center self-stretch my-auto w-5",
          "aria-label": "Toggle template selection",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args)))
        }, [
          _createElementVNode("div", {
            innerHTML: _ctx.isCollapsed ? _ctx.ARROW_DOWN : _ctx.ARROW_UP
          }, null, 8, _hoisted_4)
        ])
      ])
    ]),
    _createVNode(_Transition, {
      onBeforeEnter: _ctx.beforeEnter,
      onEnter: _ctx.enter,
      onLeave: _ctx.leave
    }, {
      default: _withCtx(() => [
        (!_ctx.isCollapsed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "flex flex-col w-full rounded-none",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTemplate('template-1')))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col justify-center p-1", {
              'border-violet-600 border border-solid rounded-xl':
                _ctx.selectedTemplate === 'template-1',
            }])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["relative flex flex-col justify-center px-3 py-2 rounded-md", {
                'bg-violet-100': _ctx.selectedTemplate === 'template-1',
                'bg-gray-100': _ctx.selectedTemplate !== 'template-1',
              }])
                  }, [
                    _cache[7] || (_cache[7] = _createElementVNode("img", {
                      loading: "lazy",
                      src: _imports_0,
                      class: "object-contain rounded-none aspect-[4.03]",
                      alt: "Template option 1"
                    }, null, -1)),
                    (_ctx.selectedTemplate === 'template-1')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: _ctx.CHECK_CIRCLE,
                          class: "absolute bottom-1 right-2 text-violet-600"
                        }, null, 8, _hoisted_6))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ]),
              _createElementVNode("div", {
                class: "flex flex-col justify-center p-1 mt-4 w-full rounded-none",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectTemplate('template-2')))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col justify-center p-1", {
              'border-violet-600 border border-solid rounded-xl':
                _ctx.selectedTemplate === 'template-2',
            }])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["relative flex flex-col justify-center px-3 py-2 rounded-md", {
                'bg-violet-100': _ctx.selectedTemplate === 'template-2',
                'bg-gray-100': _ctx.selectedTemplate !== 'template-2',
              }])
                  }, [
                    _cache[8] || (_cache[8] = _createElementVNode("img", {
                      loading: "lazy",
                      src: _imports_1,
                      class: "object-contain rounded-none aspect-[4.03]",
                      alt: "Template option 2"
                    }, null, -1)),
                    (_ctx.selectedTemplate === 'template-2')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: _ctx.CHECK_CIRCLE,
                          class: "absolute bottom-1 right-2 text-violet-600"
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ]),
              _createElementVNode("div", {
                class: "flex flex-col justify-center px-1 py-1.5 mt-4 w-full rounded-none",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectTemplate('template-3')))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col justify-center p-1", {
              'border-violet-600 border border-solid rounded-xl':
                _ctx.selectedTemplate === 'template-3',
            }])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["relative flex flex-col justify-center px-3 py-2 rounded-md", {
                'bg-violet-100': _ctx.selectedTemplate === 'template-3',
                'bg-gray-100': _ctx.selectedTemplate !== 'template-3',
              }])
                  }, [
                    _cache[9] || (_cache[9] = _createElementVNode("img", {
                      loading: "lazy",
                      src: _imports_2,
                      class: "object-contain rounded-none aspect-[4.03]",
                      alt: "Template option 3"
                    }, null, -1)),
                    (_ctx.selectedTemplate === 'template-3')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: _ctx.CHECK_CIRCLE,
                          class: "absolute bottom-1 right-2 text-violet-600"
                        }, null, 8, _hoisted_8))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ]),
              _createElementVNode("div", {
                class: "flex flex-col justify-center px-1 py-1.5 mt-4 w-full rounded-none",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectTemplate('template-4')))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col justify-center p-1", {
              'border-violet-600 border border-solid rounded-xl':
                _ctx.selectedTemplate === 'template-4',
            }])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["relative flex flex-col justify-center px-3 py-2 rounded-md", {
                'bg-violet-100': _ctx.selectedTemplate === 'template-4',
                'bg-gray-100': _ctx.selectedTemplate !== 'template-4',
              }])
                  }, [
                    _cache[10] || (_cache[10] = _createElementVNode("img", {
                      loading: "lazy",
                      src: _imports_3,
                      class: "object-contain rounded-none aspect-[4.03]",
                      alt: "Template option 4"
                    }, null, -1)),
                    (_ctx.selectedTemplate === 'template-4')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: _ctx.CHECK_CIRCLE,
                          class: "absolute bottom-1 right-2 text-violet-600"
                        }, null, 8, _hoisted_9))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ]),
              _createElementVNode("div", {
                class: "flex flex-col justify-center px-1 py-1.5 mt-4 w-full rounded-none",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectTemplate('template-5')))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col justify-center p-1", {
              'border-violet-600 border border-solid rounded-xl':
                _ctx.selectedTemplate === 'template-5',
            }])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["relative flex flex-col justify-center px-3 py-2 rounded-md", {
                'bg-violet-100': _ctx.selectedTemplate === 'template-5',
                'bg-gray-100': _ctx.selectedTemplate !== 'template-5',
              }])
                  }, [
                    _cache[11] || (_cache[11] = _createElementVNode("img", {
                      loading: "lazy",
                      src: _imports_4,
                      class: "object-contain rounded-none aspect-[4.03]",
                      alt: "Template option 5"
                    }, null, -1)),
                    (_ctx.selectedTemplate === 'template-5')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: _ctx.CHECK_CIRCLE,
                          class: "absolute bottom-1 right-2 text-violet-600"
                        }, null, 8, _hoisted_10))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ])
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onBeforeEnter", "onEnter", "onLeave"])
  ]))
}