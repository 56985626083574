<template>
  <div class="flex flex-col w-full bg-gray-50 px-4 py-2 gap-3">
    <!-- Emails as cards -->
    <div
      class="flex flex-wrap gap-2 mt-3 text-base font-medium text-center leading-5 tracking-tight text-gray-700"
    >
      <div
        v-for="(emailItem, index) in emailsList"
        :key="index"
        class="px-2 py-2 border gap-2 border-gray-300 rounded-full shadow-sm flex items-center space-x-2"
      >
        <div class="flex flex-col">
          <div
            class="flex items-center justify-center w-8 h-8 bg-violet-100 rounded-full"
          >
            {{ emailItem[0] }}
          </div>
        </div>
        <span>{{ emailItem.split("@")[0] }}</span>
        <button
          @click="removeEmail(index)"
          class="text-2xl hover:text-red-700 focus:outline-none"
        >
          &times;
        </button>
      </div>
    </div>

    <div class="flex justify-end items-center">
      <transition
        enter-active-class="transition ease-in duration-300"
        leave-active-class="transition ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          class="flex mr-2 text-primary-purple"
          v-if="sendInviteLoading || sentSuccessfully"
        >
          {{ inviteSentMessage() }}
        </div>
      </transition>
      <BaseButton
        type="button"
        @click="callSendInvite"
        :disabled="sendInviteLoading"
        class="flex justify-center cursor-pointer items-center py-2 px-4 rounded-md text-sm font-medium text-white bg-primary-purple"
        style="width: auto"
      >
        <span>Invite</span>
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "InviteEmailComponent",
  props: {
    emailsList: {
      type: Array as PropType<string[]>,
      required: true,
    },
    sendInviteLoading: {
      type: Boolean,
      default: false,
    },
    sentSuccessfully: {
      type: Boolean,
      default: false,
    },
    inviteSentMessage: {
      type: Function as PropType<() => string>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const removeEmail = (index: number) => {
      emit("remove-email", index);
    };

    const callSendInvite = () => {
      emit("send-invite");
    };

    return {
      removeEmail,
      callSendInvite,
    };
  },
});
</script>
