<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-black bg-opacity-50 px-6 flex items-center justify-center z-50"
    @click.self="closeModal"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        @click="closeModal"
      >
        ✕
      </button>
      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <!-- Icon Container -->
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <!-- Custom Image Icon -->
            <img
              src="@/assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <span
              class="text-gray-800 text-base font-semibold leading-6 text-left"
              >Add Account Balance</span
            >
          </div>
          <!-- payment method -->
          <div class="flex flex-col">
            <div
              v-if="userStore.paymentMethod?.source === 'credit_card'"
              class="flex flex-row gap-2"
            >
              <div v-html="CARD_ICON"></div>
              <span
                class="text-gray-800 text-sm font-semibold leading-6 text-left"
                >{{ cardNumber }}</span
              >
            </div>
            <div
              v-if="userStore.paymentMethod?.source === 'invoice'"
              class="flex flex-col"
            >
              <div class="flex flex-row gap-1">
                <div v-html="DOCUMENT_DOLLAR_ICON"></div>
                <span
                  class="text-gray-800 text-sm font-semibold leading-6 text-left"
                  >Invoice will be mailed to:
                  <span class="text-gray-500 text-sm leading-6 text-left">{{
                    userStore.paymentMethod.invoiceRecipientEmail
                  }}</span></span
                >
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-between gap-2">
            <CurrencyInput
              v-model="accountBalance"
              leadingText="$"
              trailingText="USD"
              placeholder="100"
            />
          </div>
          <div class="flex flex-col gap-2">
            <BaseButton
              type="button"
              class="flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center"
              :disabled="createAccountBalanceLoading"
              @click="update"
              ><span v-if="!createAccountBalanceLoading">Add Balance</span
              ><span v-else>Adding...</span></BaseButton
            >
            <BaseButton
              type="button"
              class="flex flex-col justify-center items-center p-2 w-full text-primary-purple bg-violet-100 rounded-lg text-sm font-medium leading-none text-center"
              :disabled="createAccountBalanceLoading"
              @click="goToBilling"
              ><span>Go To Billing</span></BaseButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ApolloError, gql } from "@apollo/client/core";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useUserStore } from "@/stores/user";
import { useMutation } from "@vue/apollo-composable";
import router from "@/router";
import {
  CARD_ICON,
  DOCUMENT_DOLLAR_ICON,
} from "@/assets/svg/shared/svg-constants";

const UPDATE_ACCOUNT_BALANCE = gql`
  mutation createAccountBalance($input: CreateAccountBalanceInput!) {
    createAccountBalance(input: $input) {
      errors
      message
      success
    }
  }
`;

export default defineComponent({
  name: "AddTeamMemberModal",
  components: {
    BaseButton,
    CurrencyInput,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    cardNumber: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const accountBalance = ref();
    const errorMessage = ref("");
    const userStore = useUserStore();

    const {
      mutate: createAccountBalance,
      onDone: createAccountBalanceDone,
      onError: createAccountBalanceError,
      loading: createAccountBalanceLoading,
    } = useMutation(UPDATE_ACCOUNT_BALANCE);

    const closeModal = () => {
      emit("update:isVisible", false);
    };

    const update = () => {
      if (accountBalance.value < 1) {
        errorMessage.value = "Account balance must be greater than 0";
        return;
      } else {
        errorMessage.value = "";
        createAccountBalance({
          input: {
            accountBalanceParams: {
              id: userStore.accountBalanceId,
              availableBalance: accountBalance.value,
              currency: "usd",
              isDefault: true,
              invoiceRecipient: invoiceRecipient,
              paymentMethod: {
                source: userStore.paymentMethod?.source,
                isActive: true,
                paymentMethodId: userStore.paymentMethod?.paymentMethodId,
              },
            },
          },
        });

        createAccountBalanceDone(async (response) => {
          if (response) {
            const data = response.data.createAccountBalance;
            if (data.success) {
              userStore.setInvoiceHistory(true);
              emit("update:isVisible", false);
              emit("updateAccountBalance");
            } else {
              errorMessage.value = data.errors.join(".");
            }
          }
        });

        createAccountBalanceError((error: ApolloError) => {
          errorMessage.value = error.message;
          console.log("Create account balance error: ", error.message);
        });
      }
    };

    const invoiceRecipient = () => {
      if (userStore.paymentMethod?.source === "invoice") {
        return userStore.paymentMethod.invoiceRecipientEmail;
      }
      return "";
    };

    const goToBilling = () => {
      router.push({ name: "BillingPage" });
    };

    return {
      errorMessage,
      accountBalance,
      closeModal,
      update,
      createAccountBalanceLoading,
      userStore,
      goToBilling,
      CARD_ICON,
      DOCUMENT_DOLLAR_ICON,
    };
  },
});
</script>
