import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 overflow-y-auto max-h-[300px]" }
const _hoisted_2 = { class: "flex flex-col gap-2 items-start" }
const _hoisted_3 = { class: "text-gray-800 text-base font-semibold leading-6" }
const _hoisted_4 = { class: "overflow-x-auto w-full" }
const _hoisted_5 = { class: "min-w-full border border-gray-300" }
const _hoisted_6 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_7 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_8 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_9 = {
  key: 0,
  class: "flex flex-col gap-1"
}
const _hoisted_10 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, " Card transaction history (" + _toDisplayString(_ctx.cardTransactions.length) + ") ", 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("table", _hoisted_5, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", { class: "bg-gray-150" }, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " No. "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Amount "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Card Used "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Created At ")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardTransactions, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: "hover:bg-gray-50 text-left"
            }, [
              _createElementVNode("td", _hoisted_6, _toDisplayString(index + 1), 1),
              _createElementVNode("td", _hoisted_7, " $" + _toDisplayString(row.amount.toFixed(2)), 1),
              _createElementVNode("td", _hoisted_8, [
                (row?.cardCharged)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("span", null, "**** **** **** " + _toDisplayString(row?.cardCharged?.lastFour), 1),
                      _createElementVNode("span", null, "Expiry: " + _toDisplayString(row?.cardCharged?.expMonth) + "/" + _toDisplayString(row?.cardCharged?.expYear), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.formatDateToLongDateString(row.createdAt)), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}