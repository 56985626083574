import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-wrap justify-between p-4" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_3 = { class: "flex flex-wrap gap-6" }
const _hoisted_4 = { class: "flex flex-wrap gap-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_7 = { class: "flex flex-wrap gap-2 items-center" }
const _hoisted_8 = {
  key: 0,
  class: "text-primary-purple"
}
const _hoisted_9 = { class: "flex justify-center mb-4" }
const _hoisted_10 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_11 = { class: "flex gap-5 max-md:flex-col" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_13 = {
  key: 0,
  class: "flex flex-col h-full ml-5 w-[37%] max-md:ml-0 max-md:w-full"
}
const _hoisted_14 = { class: "flex flex-col max-md:mt-8" }
const _hoisted_15 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_TopHeaderBar = _resolveComponent("TopHeaderBar")!
  const _component_PopupModal = _resolveComponent("PopupModal")!
  const _component_TopHeaderBarDesign = _resolveComponent("TopHeaderBarDesign")!
  const _component_PopupModalDesign = _resolveComponent("PopupModalDesign")!
  const _component_WidgetCodeModal = _resolveComponent("WidgetCodeModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-col text-left" }, [
        _createElementVNode("span", { class: "text-gray-900 font-inter text-base font-medium leading-6" }, "Widgets"),
        _createElementVNode("span", { class: "text-gray-500 font-inter text-sm font-normal leading-8" }, "You can update the widget components")
      ], -1)),
      (!_ctx.showEditControls)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (
            (_ctx.topHeaderBarId && _ctx.topHeaderBarVisible) ||
            (_ctx.popupModalId && _ctx.popupModalVisible)
          )
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    type: "button",
                    class: "gap-2 text-primary-purple font-inter text-base font-medium leading-6",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCodeModal = true))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Setup/Install Code ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "gap-2 text-primary-purple font-inter text-base font-medium leading-6",
                onClick: _ctx.handleEditWidget
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", { innerHTML: _ctx.EDIT_ICON }, null, 8, _hoisted_5),
                    _cache[3] || (_cache[3] = _createTextVNode(" Edit "))
                  ])
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.updateWidgetLoading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Updating..."))
                : _createCommentVNode("", true),
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "text-black border p-2 rounded-lg border-gray-300 font-inter text-base font-medium leading-6",
                onClick: _ctx.cancelEditWidget,
                disabled: _ctx.updateWidgetLoading
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Cancel ")
                ])),
                _: 1
              }, 8, ["onClick", "disabled"]),
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "bg-primary-purple text-white p-2 rounded-lg font-inter text-base font-medium leading-6",
                onClick: _ctx.handleUpdateWidget,
                disabled: _ctx.updateWidgetLoading
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Update ")
                ])),
                _: 1
              }, 8, ["onClick", "disabled"])
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Error Icon",
                class: "w-4 h-4"
              })
            ], -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("section", {
        class: _normalizeClass(["flex flex-col mx-auto bg-gray-200", {
        'w-[63%]': _ctx.showEditControls,
        'w-full': !_ctx.showEditControls,
      }]),
        style: _normalizeStyle({
        height: !_ctx.showEditControls ? _ctx.widgetPreviewHeight + 'px' : 'auto',
      })
      }, [
        (_ctx.topHeaderBarVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_TopHeaderBar, {
                topheaderText: _ctx.topheaderText,
                buttonText: _ctx.buttonText,
                buttonTextColor: _ctx.buttonTextColor,
                fontSize: _ctx.fontSize,
                fontFamily: _ctx.fontFamily,
                fontBoldness: _ctx.boldness,
                headerBackgroundColor: _ctx.headerBackgroundColor,
                headerTextColor: _ctx.headerTextColor,
                buttonBackgrounColor: _ctx.headerButtonBackgroundColor,
                expertsDirectoryUrl: _ctx.topHeaderExpertsDirectoryUrl,
                disableCloseButton: false
              }, null, 8, ["topheaderText", "buttonText", "buttonTextColor", "fontSize", "fontFamily", "fontBoldness", "headerBackgroundColor", "headerTextColor", "buttonBackgrounColor", "expertsDirectoryUrl"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.popupModalVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["flex flex-col", {
          'items-end': !_ctx.popupModalLeftOrientation,
          'items-start': _ctx.popupModalLeftOrientation,
          'mt-auto': _ctx.topHeaderBarVisible,
        }])
            }, [
              _createVNode(_component_PopupModal, {
                fontFamily: _ctx.popupModalfontFamily,
                fontSize: _ctx.popupModalfontSize,
                fontBoldness: _ctx.popupModalBoldness,
                expertsDirectoryUrl: _ctx.popupModalExpertsDirectoryUrl,
                disableCloseButton: false,
                buttonTextColor: _ctx.popupModalButtonTextColor,
                buttonText: _ctx.popupModalButtonText,
                bodyText: _ctx.popupModalBodyText,
                buttonBackgroundColor: _ctx.popupModalButtonBackgroundColor,
                textColor: _ctx.popupModalTextColor,
                bannerBackgroundColor: _ctx.popupModalBannerBackgroundColor
              }, null, 8, ["fontFamily", "fontSize", "fontBoldness", "expertsDirectoryUrl", "buttonTextColor", "buttonText", "bodyText", "buttonBackgroundColor", "textColor", "bannerBackgroundColor"])
            ], 2))
          : _createCommentVNode("", true)
      ], 6),
      (_ctx.showEditControls)
        ? (_openBlock(), _createElementBlock("section", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_TopHeaderBarDesign, {
                  isVisible: _ctx.topHeaderBarVisible,
                  fontFamily: _ctx.fontFamily,
                  fontSize: _ctx.fontSize,
                  buttonTextColor: _ctx.buttonTextColor,
                  boldness: _ctx.boldness,
                  topHeaderBackgroundColor: _ctx.headerBackgroundColor,
                  topHeaderbuttonBackgroundColor: _ctx.headerButtonBackgroundColor,
                  topHeaderTextColor: _ctx.headerTextColor,
                  headerText: _ctx.topheaderText,
                  buttonText: _ctx.buttonText,
                  topHeaderExpertsDirectoryUrl: _ctx.topHeaderExpertsDirectoryUrl,
                  onExpertsDirectoryUrlChanged: 
              _ctx.handleTopHeaderExpertsDirectoryUrlChange
            ,
                  onToggleVisibility: _ctx.handleTopHeaderBarVisibilityChange,
                  onButtonTextChanged: _ctx.handleButtonTextChange,
                  onFontFamilyChanged: _ctx.handleFontChange,
                  onBoldnessChanged: _ctx.handleBoldnessChange,
                  onButtonTextColorChanged: _ctx.handleButtonTextColorChange,
                  onHeaderBackgroundColorChanged: _ctx.handleHeaderBackgroundColorChange,
                  onButtonBackgroundColorChanged: _ctx.handleButtonBackgroundColorChange,
                  onHeaderTextColorChanged: _ctx.handleHeaderTextColorChange,
                  onFontSizeChanged: _ctx.handleFontSizeChange,
                  onHeaderTextChanged: _ctx.handleTopHeaderTextChange
                }, null, 8, ["isVisible", "fontFamily", "fontSize", "buttonTextColor", "boldness", "topHeaderBackgroundColor", "topHeaderbuttonBackgroundColor", "topHeaderTextColor", "headerText", "buttonText", "topHeaderExpertsDirectoryUrl", "onExpertsDirectoryUrlChanged", "onToggleVisibility", "onButtonTextChanged", "onFontFamilyChanged", "onBoldnessChanged", "onButtonTextColorChanged", "onHeaderBackgroundColorChanged", "onButtonBackgroundColorChanged", "onHeaderTextColorChanged", "onFontSizeChanged", "onHeaderTextChanged"]),
                _createVNode(_component_PopupModalDesign, {
                  isVisible: _ctx.popupModalVisible,
                  fontFamily: _ctx.popupModalfontFamily,
                  fontSize: _ctx.popupModalfontSize,
                  boldness: _ctx.popupModalBoldness,
                  popupButtonTextColor: _ctx.popupModalButtonTextColor,
                  popupButtonText: _ctx.popupModalButtonText,
                  popupBodyText: _ctx.popupModalBodyText,
                  popupExpertsDirectoryUrl: _ctx.popupModalExpertsDirectoryUrl,
                  popupLeftOrientation: _ctx.popupModalLeftOrientation,
                  popupButtonBackgroundColor: _ctx.popupModalButtonBackgroundColor,
                  popupTextColor: _ctx.popupModalTextColor,
                  popupBannerBackgroundColor: _ctx.popupModalBannerBackgroundColor,
                  popupMobileVisibility: _ctx.popupModalMobileVisibility,
                  onToggleVisibility: _ctx.handlePopupModalVisibilityChange,
                  onFontFamilyChanged: _ctx.handlePopupModalFontChange,
                  onBoldnessChanged: _ctx.handlePopupModalBoldnessChange,
                  onFontSizeChanged: _ctx.handlePopupModalFontSizeChange,
                  onLeftOrientationChanged: _ctx.handlePopupModalLeftOrientationChange,
                  onButtonTextColorChanged: _ctx.handlePopupModalButtonTextColorChange,
                  onButtonTextChanged: _ctx.handlePopupModalButtonTextChange,
                  onBodyTextChanged: _ctx.handlePopupModalBodyTextChange,
                  onExpertsDirectoryUrlChanged: 
              _ctx.handlePopupModalExpertsDirectoryUrlChange
            ,
                  onButtonBackgroundColorChanged: 
              _ctx.handlePopupModalButtonBackgroundColorChange
            ,
                  onTextColorChanged: _ctx.handlePopupModalTextColorChanged,
                  onBannerBackgroundColorChanged: 
              _ctx.handlePopupModalBannerBackgroundColorChange
            ,
                  onMobileVisibilityChanged: _ctx.handlePopupModalMobileVisibilityChange
                }, null, 8, ["isVisible", "fontFamily", "fontSize", "boldness", "popupButtonTextColor", "popupButtonText", "popupBodyText", "popupExpertsDirectoryUrl", "popupLeftOrientation", "popupButtonBackgroundColor", "popupTextColor", "popupBannerBackgroundColor", "popupMobileVisibility", "onToggleVisibility", "onFontFamilyChanged", "onBoldnessChanged", "onFontSizeChanged", "onLeftOrientationChanged", "onButtonTextColorChanged", "onButtonTextChanged", "onBodyTextChanged", "onExpertsDirectoryUrlChanged", "onButtonBackgroundColorChanged", "onTextColorChanged", "onBannerBackgroundColorChanged", "onMobileVisibilityChanged"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_WidgetCodeModal, {
        isVisible: _ctx.showCodeModal,
        topHeaderBarId: _ctx.topHeaderBarId,
        popupModalId: _ctx.popupModalId,
        topHeaderBarVisible: _ctx.topHeaderBarVisible,
        popupModalVisible: _ctx.popupModalVisible,
        onCloseCodeModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCodeModal = false))
      }, null, 8, ["isVisible", "topHeaderBarId", "popupModalId", "topHeaderBarVisible", "popupModalVisible"]), [
        [_vShow, _ctx.topHeaderBarId || _ctx.popupModalId]
      ])
    ])
  ], 64))
}