import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 overflow-y-auto max-h-[300px]" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col gap-2 items-start"
}
const _hoisted_4 = { class: "text-gray-800 text-base font-semibold leading-6" }
const _hoisted_5 = { class: "overflow-x-auto w-full" }
const _hoisted_6 = { class: "min-w-full border border-gray-300" }
const _hoisted_7 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_8 = { class: "flex flex-col gap-1" }
const _hoisted_9 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_10 = { class: "flex flex-col gap-1" }
const _hoisted_11 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_12 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_13 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  colspan: "5",
  class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, " Payouts history (" + _toDisplayString(_ctx.bookings.length) + ") ", 1)
        ])),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("table", _hoisted_6, [
        _cache[3] || (_cache[3] = _createElementVNode("thead", { class: "bg-gray-150" }, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " EXPERT NAME/EMAIL "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " ATTENDEE NAME/EMAIL "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " MEETING DATE "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " PAYOUT AMOUNT "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " PAYOUT FEES ")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookings, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: "hover:bg-gray-50 text-left"
            }, [
              _createElementVNode("td", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(row.expertFirstName) + " " + _toDisplayString(row.expertLastName), 1),
                  _createElementVNode("span", null, _toDisplayString(row.expertEmail), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(row.attendeeName), 1),
                  _createElementVNode("span", null, _toDisplayString(row.attendeeEmail), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.formatDateToLongDateString(row.meetingDate)), 1),
              _createElementVNode("td", _hoisted_12, " $" + _toDisplayString(row.payout.toFixed(2)), 1),
              _createElementVNode("td", _hoisted_13, " $" + _toDisplayString(row.fees.toFixed(2)), 1)
            ]))
          }), 128)),
          (_ctx.moreRecordsAvailable)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                _createElementVNode("td", _hoisted_15, [
                  _createVNode(_component_BaseButton, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchMoreRecords())),
                    class: "px-4 py-1 text-gray-900 text-sm font-medium leading-5 border border-gray-300 rounded-md"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" View More ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}