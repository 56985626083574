import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg shadow-lg p-6 max-w-2xl my-2 w-full relative max-h-[90vh] overflow-y-auto" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex z-10 gap-8 justify-start items-center border-b border-gray-200 self-start mt-8 mx-10 max-md:ml-2.5" }
const _hoisted_4 = { class: "flex flex-col justify-center self-stretch my-auto text-sm font-medium leading-none text-violet-600 whitespace-nowrap" }
const _hoisted_5 = { class: "flex gap-2 justify-center items-center self-center px-1 pb-4" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-col mt-8 max-w-full mx-10" }
const _hoisted_8 = { class: "flex flex-col gap-5 max-md:flex-col" }
const _hoisted_9 = { class: "flex flex-col w-full max-md:ml-0 max-md:w-full" }
const _hoisted_10 = { class: "flex flex-col grow mt-3 text-sm font-medium leading-5 text-black max-md:mt-7 max-md:max-w-full" }
const _hoisted_11 = {
  ref: "codeSnippet",
  class: "self-start text-left overflow-x-auto p-4 rounded-md max-w-full break-all whitespace-pre-wrap bg-gray-100 border border-gray-300"
}
const _hoisted_12 = { class: "language-html break-all" }
const _hoisted_13 = { class: "flex flex-col w-full max-md:ml-0 max-md:w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["self"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "absolute top-2 right-2 text-gray-500 hover:text-gray-700",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, " ✕ "),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.HTML_ICON,
                    class: "text-primary-purple"
                  }, null, 8, _hoisted_6),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "self-stretch my-auto" }, "HTML", -1))
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex w-full bg-violet-600 min-h-[2px]" }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("pre", _hoisted_11, [
                      _cache[4] || (_cache[4] = _createTextVNode("")),
                      _createElementVNode("code", _hoisted_12, "\n# div should be placed at the bottom of the,\n# outer most container of the webpage.\n\n" + _toDisplayString(_ctx.computedCompanyWidgetCode) + "\n\n<script src=\"https://" + _toDisplayString(_ctx.awsBucket) + ".s3.us-east-1.amazonaws.com/" + _toDisplayString(_ctx.widgetDirectory) + "/bundle.js\"></script>\n<link rel=\"stylesheet\" href=\"https://" + _toDisplayString(_ctx.awsBucket) + ".s3.us-east-1.amazonaws.com/" + _toDisplayString(_ctx.widgetDirectory) + "/bundle.css\">\n", 1),
                      _cache[5] || (_cache[5] = _createTextVNode("\n    "))
                    ], 512)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_BaseButton, {
                    type: "button",
                    onClick: _ctx.copyCodeToClipboard,
                    class: "overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 w-full text-base font-medium text-indigo-700 bg-indigo-50 rounded-md shadow-sm max-md:mt-4"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}