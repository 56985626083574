<template>
  <div class="flex flex-col gap-4 overflow-y-auto max-h-[300px]">
    <div class="flex flex-col gap-2 items-start">
      <span class="text-gray-800 text-base font-semibold leading-6">
        Invoice history ({{ rows.length }})
      </span>
    </div>

    <div class="overflow-x-auto w-full">
      <table class="min-w-full border border-gray-300">
        <thead class="bg-gray-150">
          <tr>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Invoice Number
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Due Date
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Status
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in rows"
            :key="row.invoiceNumber"
            class="hover:bg-gray-50 text-left"
          >
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              {{ row.invoiceNumber }}
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              {{ row.dueDate }}
            </td>
            <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
              <span
                class="px-2 rounded-lg"
                :class="{
                  'text-green-800 bg-green-100': row.status === 'paid',
                  'text-red-800 bg-red-100': row.status === 'pending',
                }"
                >{{ row.status.toUpperCase() }}</span
              >
            </td>
            <td class="px-4 py-2 border-b">
              <BaseButton
                @click="viewInvoice(index)"
                class="px-4 py-1 text-gray-900 text-sm font-medium leading-5 border border-gray-300 rounded-md"
              >
                View
              </BaseButton>
            </td>
          </tr>
          <tr v-if="hasMoreInvoices">
            <td
              colspan="5"
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              <BaseButton
                @click="fetchMoreInvoices()"
                class="px-4 py-1 text-gray-900 text-sm font-medium leading-5 border border-gray-300 rounded-md"
              >
                View More
              </BaseButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import BaseButton from "@/components/shared/BaseButton.vue";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";

interface TableRow {
  invoiceNumber: string;
  dueDate: Date;
  status: string;
  hostedInvoiceUrl: string;
}

interface Edge {
  node: {
    id: string;
    status: string;
    invoiceNumber: string;
    totalAmount: number;
    dueDate: Date;
    hostedInvoiceUrl: string;
  };
}

const GET_INVOICES = gql`
  query getInvoices($first: Int!) {
    getInvoices(first: $first) {
      edges {
        node {
          id
          status
          invoiceNumber
          totalAmount
          dueDate
          hostedInvoiceUrl
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export default defineComponent({
  name: "InvoiceHistory",
  components: {
    BaseButton,
  },
  props: {
    handleError: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const numberOfInvoices = ref(10);
    const rows = ref<TableRow[]>([]);
    const hasMoreInvoices = ref(false);
    const userStore = useUserStore();

    const formatDateToLongDateString = (dateString: Date): string => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const fetchInvoices = () => {
      const { onResult: queryInvoicesResult, onError: queryInvoicesError } =
        useQuery(
          GET_INVOICES,
          {
            first: numberOfInvoices.value,
          },
          {
            fetchPolicy: "network-only",
          }
        );

      queryInvoicesResult((result) => {
        if (result) {
          if (result) {
            if ((result?.errors?.length ?? 0) > 0) {
              props.handleError("invoice-history");
            } else if (result.data?.getInvoices?.edges?.length > 0) {
              rows.value = result.data.getInvoices.edges.map((edge: Edge) => ({
                id: edge.node.id,
                status: edge.node.status,
                invoiceNumber: edge.node.invoiceNumber,
                totalAmount: edge.node.totalAmount,
                dueDate: formatDateToLongDateString(edge.node.dueDate),
                hostedInvoiceUrl: edge.node.hostedInvoiceUrl,
              }));

              hasMoreInvoices.value =
                result.data.getInvoices.pageInfo.hasNextPage;
            }
          }
        }
      });

      queryInvoicesError((error) => {
        if (error) {
          props.handleError("invoice-history");
        }
      });
    };

    const fetchMoreInvoices = () => {
      numberOfInvoices.value += 10;
      fetchInvoices();
    };

    watch(
      () => userStore.updateInvoiceHistory,
      (newValue) => {
        if (newValue) {
          fetchInvoices();
          userStore.setInvoiceHistory(null);
        }
      },
      { immediate: true }
    );

    const viewInvoice = (index: number) => {
      window.open(rows.value[index].hostedInvoiceUrl, "_blank");
    };

    onMounted(() => {
      fetchInvoices();
    });

    return {
      rows,
      viewInvoice,
      hasMoreInvoices,
      fetchMoreInvoices,
      fetchInvoices,
    };
  },
});
</script>
