<template>
  <div class="flex flex-wrap w-full justify-between gap-2">
    <!-- experts activity -->
    <div class="flex flex-col gap-3 flex-1 min-w-[300px]">
      <div class="flex">
        <span
          class="text-gray-900 text-left font-sans text-lg font-semibold leading-9"
          >Experts Activity</span
        >
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex flex-wrap w-full gap-4">
          <!-- Tab 1 -->
          <div
            class="flex-1 cursor-pointer min-w-[150px] w-full sm:w-auto"
            role="button"
            tabindex="0"
            @click="changeTab(1)"
          >
            <div class="flex flex-col w-full">
              <div
                class="flex px-4 py-5 gap-4 items-center border rounded-lg w-full"
                :class="
                  selectedTab === 1
                    ? 'border-primary-purple'
                    : 'border-gray-200'
                "
              >
                <!-- Icon Section -->
                <div
                  class="flex justify-center items-center w-12 h-12 rounded-md flex-shrink-0"
                  :class="
                    selectedTab === 1
                      ? 'bg-primary-purple text-white'
                      : 'bg-purple-100 text-primary-purple'
                  "
                >
                  <div v-html="PERSON_ICON"></div>
                </div>
                <!-- Text Section -->
                <div class="flex flex-col text-left w-full">
                  <span
                    class="text-gray-500 font-sans text-sm font-medium leading-5"
                  >
                    Total <br />Experts
                  </span>
                  <span
                    class="text-gray-900 font-sans text-sm font-medium leading-5"
                  >
                    {{ totalExperts }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Tab 2 -->
          <div
            class="flex-1 cursor-pointer min-w-[150px] w-full sm:w-auto"
            role="button"
            tabindex="0"
            @click="changeTab(2)"
          >
            <div class="flex flex-col w-full">
              <div
                class="flex px-4 py-5 gap-4 items-center border rounded-lg w-full"
                :class="
                  selectedTab === 2
                    ? 'border-primary-purple'
                    : 'border-gray-200'
                "
              >
                <!-- Icon Section -->
                <div
                  class="flex justify-center items-center w-12 h-12 rounded-md flex-shrink-0"
                  :class="
                    selectedTab === 2
                      ? 'bg-primary-purple text-white'
                      : 'bg-purple-100 text-primary-purple'
                  "
                >
                  <div v-html="CURSOR_CLICK_ICON"></div>
                </div>
                <!-- Text Section -->
                <div class="flex flex-col text-left w-full">
                  <span
                    class="text-gray-500 font-sans text-sm font-medium leading-5"
                  >
                    Booking System <br />Views
                  </span>
                  <span
                    class="text-gray-900 font-sans text-sm font-medium leading-5"
                  >
                    {{ totalDirectoryVisits }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Tab 3 -->
          <div
            class="flex-1 cursor-pointer min-w-[195px] w-full sm:w-auto"
            role="button"
            tabindex="0"
            @click="changeTab(3)"
          >
            <div class="flex flex-col w-full">
              <div
                class="flex px-4 py-5 gap-4 items-center border rounded-lg w-full"
                :class="
                  selectedTab === 3
                    ? 'border-primary-purple'
                    : 'border-gray-200'
                "
              >
                <!-- Icon Section -->
                <div
                  class="flex justify-center items-center w-12 h-12 rounded-md flex-shrink-0"
                  :class="
                    selectedTab === 3
                      ? 'bg-primary-purple text-white'
                      : 'bg-purple-100 text-primary-purple'
                  "
                >
                  <div v-html="PEOPLE_ICON"></div>
                </div>
                <!-- Text Section -->
                <div class="flex flex-col text-left w-full">
                  <span
                    class="text-gray-500 font-sans text-sm font-medium leading-5"
                  >
                    Expert <br />Meetings
                  </span>
                  <span
                    class="whitespace-nowrap font-sans text-sm font-medium leading-5"
                  >
                    {{ totalBookings }}
                    <span
                      v-if="totalUpcomingBookings > 0"
                      class="text-primary-purple whitespace-nowrap bg-purple-100 py-0.5 px-1 rounded-md font-sans text-xs font-medium leading-5 ml-1"
                    >
                      Upcoming {{ totalUpcomingBookings }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full border border-primary-purple rounded-b-lg">
          <Bar
            id="my-chart-id"
            :options="chartOptions"
            :data="chartData"
            :key="graphKey"
          />
        </div>
      </div>
    </div>
    <!-- experts roi -->
    <div class="flex flex-1 min-w-[300px] sm:w-auto">
      <div class="flex flex-col gap-3">
        <div class="flex">
          <span
            class="text-gray-900 text-left font-sans text-lg font-semibold leading-9"
            >Experts Activity</span
          >
        </div>
        <div class="flex flex-col w-full h-full">
          <img
            src="../../assets/analytics/roi-section.svg"
            class="w-full object-contain"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PERSON_ICON,
  CURSOR_CLICK_ICON,
  PEOPLE_ICON,
} from "@/assets/svg/shared/svg-constants";
import { computed, defineComponent, onMounted, ref } from "vue";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { AnalyticsGraphsData } from "@/types/analytics-types";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

interface ChartData {
  labels: string[];
  datasets: { label: string; data: number[] }[];
}

export default defineComponent({
  name: "AnalyticsGraphs",
  components: { Bar },
  props: {
    totalExperts: {
      type: Number,
      required: true,
    },
    totalBookings: {
      type: Number,
      required: true,
    },
    totalDirectoryVisits: {
      type: Number,
      required: true,
    },
    totalUpcomingBookings: {
      type: Number,
      required: true,
    },
    bookingsByDate: {
      type: Array,
      required: true,
    },
    expertsByDate: {
      type: Array,
      required: true,
    },
    directoryVisitsByDate: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const selectedTab = ref(1);
    const graphKey = ref(0);
    const chartOptions = ref({
      responsive: true,
      backgroundColor: "#6A48F3",
    });
    const data = ref<ChartData>({
      labels: [],
      datasets: [{ label: "Experts", data: [] }],
    });

    const chartData = computed(() => data.value);

    const changeTab = (tab: number) => {
      selectedTab.value = tab;

      if (tab === 1 && props.expertsByDate) {
        const localExpertsByDate = props.expertsByDate as AnalyticsGraphsData[];
        data.value = {
          labels: localExpertsByDate.map((item) => convertDateToMDY(item.date)),
          datasets: [
            {
              label: "Experts",
              data: localExpertsByDate.map((item) => item.total),
            },
          ],
        };
      }

      if (tab === 2 && props.directoryVisitsByDate) {
        const localDirectoryVisitsByDate =
          props.directoryVisitsByDate as AnalyticsGraphsData[];
        data.value = {
          labels: localDirectoryVisitsByDate.map((item) =>
            convertDateToMDY(item.date)
          ),
          datasets: [
            {
              label: "Booking System Views",
              data: localDirectoryVisitsByDate.map((item) => item.total),
            },
          ],
        };
      }

      if (tab === 3 && props.bookingsByDate) {
        const localBookingsByDate =
          props.bookingsByDate as AnalyticsGraphsData[];
        data.value = {
          labels: localBookingsByDate.map((item) =>
            convertDateToMDY(item.date)
          ),
          datasets: [
            {
              label: "Expert Meetings",
              data: localBookingsByDate.map((item) => item.total),
            },
          ],
        };
      }

      graphKey.value++;
    };

    const convertDateToMDY = (dateString: string): string => {
      console.log("dateString", dateString);
      const parts = dateString.split("-");
      const [year, month, day] = parts;
      let newDateString = "";
      if (day) {
        newDateString = `${month}-${day}-${year}`;
      } else {
        newDateString = `${month}-${year}`;
      }

      return newDateString;
    };

    onMounted(() => {
      changeTab(1);
    });

    return {
      selectedTab,
      PERSON_ICON,
      CURSOR_CLICK_ICON,
      PEOPLE_ICON,
      chartOptions,
      chartData,
      changeTab,
      graphKey,
    };
  },
});
</script>
