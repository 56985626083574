import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex flex-grow max-md:flex-col" }
const _hoisted_3 = { class: "flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple" }
const _hoisted_4 = { class: "flex flex-col mt-14 flex-grow" }
const _hoisted_5 = { class: "flex flex-col w-4/5 justify-start mt-10 pt-6 pb-1 max-md:w-full" }
const _hoisted_6 = { class: "flex justify-center mb-4" }
const _hoisted_7 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_8 = { class: "flex flex-col justify-center max-md:mt-4 max-md:w-full" }
const _hoisted_9 = { class: "flex flex-col mx-auto w-3/5" }
const _hoisted_10 = { class: "flex flex-col z-20 gap-5 justify-between mt-2 text-left" }
const _hoisted_11 = { class: "flex flex-col flex-grow basis-1/2" }
const _hoisted_12 = { class: "flex flex-col border b-1 p-6" }
const _hoisted_13 = { class: "flex flex-row justify-between items-center" }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-wrap items-center my-3 gap-4 justify-start overflow-x-hidden"
}
const _hoisted_15 = { class: "flex-1 w-1/2 sm:w-2/3 md:w-full" }
const _hoisted_16 = { class: "flex-1 w-1/2 sm:w-2/3 md:w-full" }
const _hoisted_17 = { class: "flex flex-col font-bold text-sm" }
const _hoisted_18 = { class: "flex flex-row p-3 bg-gray-200 rounded-md" }
const _hoisted_19 = { class: "flex flex-row w-1/2 rounded-md" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "flex flex-row w-1/2" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "flex flex-wrap justify-center mt-auto items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsList = _resolveComponent("StepsList")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_StripeCardFields = _resolveComponent("StripeCardFields")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("aside", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex flex-col ml-10 text-left" }, [
            _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, [
              _createTextVNode(" Get Started "),
              _createElementVNode("br")
            ])
          ], -1)),
          _createVNode(_component_StepsList, { currentStep: 1 })
        ])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Error Icon",
                    class: "w-4 h-4"
                  })
                ], -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "text-3xl font-semibold leading-9 text-left text-zinc-800" }, " Billing ", -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "accountBalance",
                  text: "Account Balance",
                  class: "text-sm font-medium leading-5 text-zinc-800 text-left"
                }),
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-xs my-1" }, " Enter the amount to add to your total balance right now ", -1)),
                _createElementVNode("div", null, [
                  _createVNode(_component_CurrencyInput, {
                    modelValue: _ctx.accountBalance,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountBalance) = $event)),
                    leadingText: "$",
                    trailingText: "USD",
                    placeholder: "100"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "font-semibold leading-9 text-left text-zinc-800" }, " Auto Top-up ", -1)),
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAutoTopup && _ctx.toggleAutoTopup(...args))),
                    class: _normalizeClass([
                      _ctx.isAutoTopupEnabled ? 'bg-primary-purple' : 'bg-gray-200',
                      'flex items-center h-6 w-11 rounded-full p-1 transition-colors ease-in-out duration-300',
                    ])
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass([
                        _ctx.isAutoTopupEnabled ? 'translate-x-5' : 'translate-x-0',
                        'block w-4 h-4 transform bg-white rounded-full transition-transform ease-in-out duration-300',
                      ])
                    }, null, 2)
                  ], 2)
                ]),
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex flex-col my-3" }, [
                  _createElementVNode("span", { class: "text-sm leading-tight" }, "Enter the amount you want to automatically top up your account.")
                ], -1)),
                (_ctx.isAutoTopupEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", null, [
                        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-sm mr-2 font-bold" }, " If amount goes below", -1)),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_CurrencyInput, {
                            modelValue: _ctx.topUpLimit,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.topUpLimit) = $event)),
                            leadingText: "$",
                            trailingText: "USD",
                            placeholder: ""
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-sm mx-2 font-bold" }, " Auto top-up for", -1)),
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_CurrencyInput, {
                            modelValue: _ctx.topUpAmount,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.topUpAmount) = $event)),
                            leadingText: "$",
                            trailingText: "USD",
                            placeholder: ""
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[16] || (_cache[16] = _createElementVNode("span", null, "Add Billing Method", -1)),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex flex-row p-3 w-full justify-center items-center rounded-md", {
                        'bg-primary-purple text-white': _ctx.isPaymentMethodCard,
                        'text-secondary-gray': !_ctx.isPaymentMethodCard,
                      }]),
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setPaymentMethodToCard && _ctx.setPaymentMethodToCard(...args)))
                    }, [
                      _createElementVNode("div", {
                        innerHTML: _ctx.CARD_ICON,
                        class: _normalizeClass(["mr-2", {
                          'text-white': _ctx.isPaymentMethodCard,
                          'text-secondary-gray': !_ctx.isPaymentMethodCard,
                        }])
                      }, null, 10, _hoisted_20),
                      _cache[14] || (_cache[14] = _createTextVNode(" Card "))
                    ], 2)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex flex-row p-3 w-full justify-center items-center rounded-md", {
                        'bg-primary-purple text-white': !_ctx.isPaymentMethodCard,
                        'text-secondary-gray': _ctx.isPaymentMethodCard,
                      }]),
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.setPaymentMethodToInvoice && _ctx.setPaymentMethodToInvoice(...args)))
                    }, [
                      _createElementVNode("div", {
                        innerHTML: _ctx.DOCUMENT_DOLLAR_ICON,
                        class: _normalizeClass(["mr-2", {
                          'text-white': !_ctx.isPaymentMethodCard,
                          'text-secondary-gray': _ctx.isPaymentMethodCard,
                        }])
                      }, null, 10, _hoisted_22),
                      _cache[15] || (_cache[15] = _createTextVNode(" Invoice "))
                    ], 2)
                  ])
                ])
              ]),
              (_ctx.isPaymentMethodCard)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createVNode(_component_StripeCardFields, {
                      onPaymentMethodId: _ctx.handlePaymentMethodId,
                      onError: _ctx.handleError
                    }, null, 8, ["onPaymentMethodId", "onError"])
                  ]))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "invoiceEmail",
                  text: "Invoice Recipient Email",
                  class: "text-sm font-medium leading-5 text-zinc-800 text-left"
                }),
                _createVNode(_component_BaseInput, {
                  type: "email",
                  id: "invoiceEmail",
                  modelValue: _ctx.invoiceEmail,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.invoiceEmail) = $event)),
                  placeholder: "you@example.com",
                  required: "",
                  className: "px-3.5 py-2.5 mt-1 text-gray-500 bg-white rounded-md border border-solid shadow-sm w-full"
                }, null, 8, ["modelValue"])
              ], 512), [
                [_vShow, !_ctx.isPaymentMethodCard]
              ])
            ])
          ])
        ]),
        _createElementVNode("footer", _hoisted_24, [
          _createVNode(_component_FooterSection, {
            isDisabled: _ctx.isDisabled,
            onNext: _ctx.goNext
          }, null, 8, ["isDisabled", "onNext"])
        ])
      ])
    ])
  ]))
}