<template>
  <div
    class="flex flex-col bg-white rounded-xl border border-gray-300 border-solid"
  >
    <div
      class="flex flex-col justify-center px-6 py-5 w-full rounded-xl bg-slate-50 max-md:px-5"
    >
      <div class="flex gap-10 justify-between items-center w-full">
        <div class="flex items-center gap-2">
          <div
            class="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 text-black font-semibold text-sm"
          >
            1
          </div>
          <h3 class="text-sm font-semibold text-black">Select Template</h3>
        </div>
        <button
          class="flex justify-between items-center self-stretch my-auto w-5"
          aria-label="Toggle template selection"
          @click="toggleCollapse"
        >
          <div v-html="isCollapsed ? ARROW_DOWN : ARROW_UP"></div>
        </button>
      </div>
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div
        class="flex flex-col self-center mt-4 w-full px-4"
        v-if="!isCollapsed"
        ref="collapsible"
      >
        <div
          class="flex flex-col w-full rounded-none"
          @click="selectTemplate('template-1')"
        >
          <div
            class="flex flex-col justify-center p-1"
            :class="{
              'border-violet-600 border border-solid rounded-xl':
                selectedTemplate === 'template-1',
            }"
          >
            <div
              class="relative flex flex-col justify-center px-3 py-2 rounded-md"
              :class="{
                'bg-violet-100': selectedTemplate === 'template-1',
                'bg-gray-100': selectedTemplate !== 'template-1',
              }"
            >
              <img
                loading="lazy"
                src="@/assets/expert-program/directory/directory-template-option-one.svg"
                class="object-contain rounded-none aspect-[4.03]"
                alt="Template option 1"
              />

              <div
                v-if="selectedTemplate === 'template-1'"
                v-html="CHECK_CIRCLE"
                class="absolute bottom-1 right-2 text-violet-600"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col justify-center p-1 mt-4 w-full rounded-none"
          @click="selectTemplate('template-2')"
        >
          <div
            class="flex flex-col justify-center p-1"
            :class="{
              'border-violet-600 border border-solid rounded-xl':
                selectedTemplate === 'template-2',
            }"
          >
            <div
              class="relative flex flex-col justify-center px-3 py-2 rounded-md"
              :class="{
                'bg-violet-100': selectedTemplate === 'template-2',
                'bg-gray-100': selectedTemplate !== 'template-2',
              }"
            >
              <img
                loading="lazy"
                src="@/assets/expert-program/directory/directory-template-option-two.svg"
                class="object-contain rounded-none aspect-[4.03]"
                alt="Template option 2"
              />

              <div
                v-if="selectedTemplate === 'template-2'"
                v-html="CHECK_CIRCLE"
                class="absolute bottom-1 right-2 text-violet-600"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col justify-center px-1 py-1.5 mt-4 w-full rounded-none"
          @click="selectTemplate('template-3')"
        >
          <div
            class="flex flex-col justify-center p-1"
            :class="{
              'border-violet-600 border border-solid rounded-xl':
                selectedTemplate === 'template-3',
            }"
          >
            <div
              class="relative flex flex-col justify-center px-3 py-2 rounded-md"
              :class="{
                'bg-violet-100': selectedTemplate === 'template-3',
                'bg-gray-100': selectedTemplate !== 'template-3',
              }"
            >
              <img
                loading="lazy"
                src="@/assets/expert-program/directory/directory-template-option-three.svg"
                class="object-contain rounded-none aspect-[4.03]"
                alt="Template option 3"
              />

              <div
                v-if="selectedTemplate === 'template-3'"
                v-html="CHECK_CIRCLE"
                class="absolute bottom-1 right-2 text-violet-600"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col justify-center px-1 py-1.5 mt-4 w-full rounded-none"
          @click="selectTemplate('template-4')"
        >
          <div
            class="flex flex-col justify-center p-1"
            :class="{
              'border-violet-600 border border-solid rounded-xl':
                selectedTemplate === 'template-4',
            }"
          >
            <div
              class="relative flex flex-col justify-center px-3 py-2 rounded-md"
              :class="{
                'bg-violet-100': selectedTemplate === 'template-4',
                'bg-gray-100': selectedTemplate !== 'template-4',
              }"
            >
              <img
                loading="lazy"
                src="@/assets/expert-program/directory/directory-template-option-four.svg"
                class="object-contain rounded-none aspect-[4.03]"
                alt="Template option 4"
              />

              <div
                v-if="selectedTemplate === 'template-4'"
                v-html="CHECK_CIRCLE"
                class="absolute bottom-1 right-2 text-violet-600"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col justify-center px-1 py-1.5 mt-4 w-full rounded-none"
          @click="selectTemplate('template-5')"
        >
          <div
            class="flex flex-col justify-center p-1"
            :class="{
              'border-violet-600 border border-solid rounded-xl':
                selectedTemplate === 'template-5',
            }"
          >
            <div
              class="relative flex flex-col justify-center px-3 py-2 rounded-md"
              :class="{
                'bg-violet-100': selectedTemplate === 'template-5',
                'bg-gray-100': selectedTemplate !== 'template-5',
              }"
            >
              <img
                loading="lazy"
                src="@/assets/expert-program/directory/directory-template-option-five.svg"
                class="object-contain rounded-none aspect-[4.03]"
                alt="Template option 5"
              />

              <div
                v-if="selectedTemplate === 'template-5'"
                v-html="CHECK_CIRCLE"
                class="absolute bottom-1 right-2 text-violet-600"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  CHECK_CIRCLE,
  ARROW_UP,
  ARROW_DOWN,
} from "@/assets/svg/shared/svg-constants";

export default defineComponent({
  name: "TemplateSelector",
  props: {
    initialSelected: {
      type: String,
    },
  },
  emits: ["templateSelected"],
  setup(props, { emit }) {
    const selectedTemplate = ref<string | null>(props.initialSelected || null);
    const isCollapsed = ref(false);

    const selectTemplate = (templateId: string) => {
      selectedTemplate.value = templateId;
      emit("templateSelected", templateId);
    };

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const beforeEnter = (el: Element) => {
      const target = el as HTMLElement;
      target.style.height = "0";
      target.style.opacity = "0";
    };

    const enter = (el: Element) => {
      const target = el as HTMLElement;
      const height = target.scrollHeight;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = height + "px";
      target.style.opacity = "1";
    };

    const leave = (el: Element) => {
      const target = el as HTMLElement;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = "0";
      target.style.opacity = "0";
    };

    return {
      selectedTemplate,
      selectTemplate,
      CHECK_CIRCLE,
      isCollapsed,
      toggleCollapse,
      ARROW_UP,
      ARROW_DOWN,
      beforeEnter,
      enter,
      leave,
    };
  },
});
</script>
