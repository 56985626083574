import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../assets/shared/widget-banner.svg'


const _hoisted_1 = {
  key: 0,
  class: "flex flex-col px-3 mt-4"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex flex-col w-full overflow-hidden" }
const _hoisted_4 = { class: "flex flex-row w-full gap-2" }
const _hoisted_5 = { class: "w-2/3 flex justify-center items-center" }
const _hoisted_6 = { class: "flex flex-col gap-6 pl-1 pr-4 py-4" }
const _hoisted_7 = { class: "flex flex-col text-white gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "inline-flex gap-2 rounded-lg bg-slate-800 max-w-max px-2 relative",
          style: _normalizeStyle({
        fontFamily: _ctx.fontFamily,
        backgroundColor: _ctx.bannerBackgroundColor,
      })
        }, [
          _createElementVNode("div", {
            class: "absolute top-1 right-1 w-5 h-5 p-0.5 bg-white rounded-full flex items-center justify-center cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.CROSS_ICON,
              class: "text-black"
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", {
                      class: "text-base font-normal text-left",
                      style: _normalizeStyle({
                    fontSize: _ctx.fontSize + 'px',
                    fontWeight: _ctx.fontBoldness,
                    color: _ctx.textColor,
                    maxWidth: '150px',
                    wordWrap: 'break-word',
                  })
                    }, _toDisplayString(_ctx.bodyText), 5)
                  ]),
                  _createVNode(_component_BaseButton, {
                    type: "button",
                    onClick: _ctx.goToExpertsDirectory,
                    class: "flex flex-col justify-center px-5 py-1 max-w-max text-sm font-medium leading-none text-indigo-600 bg-gray-50 rounded-md",
                    style: _normalizeStyle({
                  color: _ctx.buttonTextColor,
                  fontSize: _ctx.fontSize + 'px',
                  fontWeight: _ctx.fontBoldness,
                  backgroundColor: _ctx.buttonBackgroundColor,
                })
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "style"])
                ])
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-1/3 flex justify-center items-start mr-3" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "",
                  class: "w-32 p-1 mt-3 shrink-0",
                  loading: "lazy"
                })
              ], -1))
            ])
          ])
        ], 4)
      ]))
    : _createCommentVNode("", true)
}