import { Mark } from "@tiptap/core";

export const useTiptapMarks = () => {
  const Boldness = Mark.create({
    name: "boldness",

    addOptions() {
      return {
        types: ["textStyle"],
      };
    },

    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            boldness: {
              default: null,
              parseHTML: (element) => element.style.fontWeight || null,
              renderHTML: (attributes) => {
                if (!attributes.boldness) {
                  return {};
                }
                return { style: `font-weight: ${attributes.boldness};` };
              },
            },
          },
        },
      ];
    },
  });

  const FontFamily = Mark.create({
    name: "fontFamily",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            fontFamily: {
              default: null,
              parseHTML: (element) =>
                element.style.fontFamily.replace(/['"]+/g, ""),
              renderHTML: (attributes) => {
                if (!attributes.fontFamily) {
                  return {};
                }
                return { style: `font-family: ${attributes.fontFamily}` };
              },
            },
          },
        },
      ];
    },
  });

  const FontSize = Mark.create({
    name: "fontSize",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            fontSize: {
              default: null,
              parseHTML: (element) => element.style.fontSize.replace(/px/, ""),
              renderHTML: (attributes) => {
                if (!attributes.fontSize) {
                  return {};
                }
                return { style: `font-size: ${attributes.fontSize}px` };
              },
            },
          },
        },
      ];
    },
  });

  const TextColor = Mark.create({
    name: "textColor",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            color: {
              default: null,
              parseHTML: (element) => element.style.color,
              renderHTML: (attributes) => {
                if (!attributes.color) {
                  return {};
                }
                return { style: `color: ${attributes.color}` };
              },
            },
          },
        },
      ];
    },
  });

  const ButtonBackgroundColor = Mark.create({
    name: "buttonBackgroundColor",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            buttonBackgroundColor: {
              default: null,
              parseHTML: (element) => element.style.backgroundColor,
              renderHTML: (attributes) => {
                if (!attributes.buttonBackgroundColor) {
                  return {};
                }
                return {
                  style: `background-color: ${attributes.buttonBackgroundColor}`,
                };
              },
            },
          },
        },
      ];
    },
  });

  const HeaderBackgroundColor = Mark.create({
    name: "headerBackgroundColor",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            headerBackgroundColor: {
              default: null,
              parseHTML: (element) => element.style.backgroundColor,
              renderHTML: (attributes) => {
                if (!attributes.headerBackgroundColor) {
                  return {};
                }
                return {
                  style: `background-color: ${attributes.headerBackgroundColor}`,
                };
              },
            },
          },
        },
      ];
    },
  });

  const HeaderTextColor = Mark.create({
    name: "headerTextColor",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            headerTextColor: {
              default: null,
              parseHTML: (element) => element.style.color,
              renderHTML: (attributes) => {
                if (!attributes.headerTextColor) {
                  return {};
                }
                return {
                  style: `color: ${attributes.headerTextColor}`,
                };
              },
            },
          },
        },
      ];
    },
  });

  const BannerBackgroundColor = Mark.create({
    name: "bannerBackgroundColor",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            bannerBackgroundColor: {
              default: null,
              parseHTML: (element) => element.style.backgroundColor,
              renderHTML: (attributes) => {
                if (!attributes.bannerBackgroundColor) {
                  return {};
                }
                return {
                  style: `background-color: ${attributes.bannerBackgroundColor}`,
                };
              },
            },
          },
        },
      ];
    },
  });

  const BackgroundColor = Mark.create({
    name: "backgroundColor",
    addOptions() {
      return {
        types: ["textStyle"],
      };
    },
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            backgroundColor: {
              default: null,
              parseHTML: (element) => element.style.backgroundColor,
              renderHTML: (attributes) => {
                if (!attributes.backgroundColor) {
                  return {};
                }
                return {
                  style: `background-color: ${attributes.backgroundColor}`,
                };
              },
            },
          },
        },
      ];
    },
  });

  return {
    Boldness,
    FontFamily,
    FontSize,
    TextColor,
    ButtonBackgroundColor,
    HeaderBackgroundColor,
    HeaderTextColor,
    BannerBackgroundColor,
    BackgroundColor,
  };
};
