import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-col min-h-screen bg-gray-100" }
const _hoisted_2 = { class: "flex flex-col w-full border-b" }
const _hoisted_3 = { class: "flex flex-col gap-6 mb-16 p-6" }
const _hoisted_4 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_5 = {
  key: 1,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_6 = { class: "flex flex-col gap-6" }
const _hoisted_7 = { class: "flex flex-col gap-4 border border-gray-200 rounded-lg p-4" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "flex flex-row max-w-max gap-2 bg-gray-200 rounded-lg p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_CurrentAccountBalance = _resolveComponent("CurrentAccountBalance")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PayoutsHistory = _resolveComponent("PayoutsHistory")!
  const _component_InvoiceHistory = _resolveComponent("InvoiceHistory")!
  const _component_CardTransactionHistory = _resolveComponent("CardTransactionHistory")!
  const _component_UpdateAccountBalance = _resolveComponent("UpdateAccountBalance")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_Navbar, { activeTab: 0 })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isLoadingAccountBalance)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-col items-start" }, [
        _createElementVNode("span", { class: "text-black text-3xl font-semibold leading-9" }, "Billing")
      ], -1)),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Error Icon",
                class: "w-4 h-4"
              })
            ], -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createVNode(_component_CurrentAccountBalance, {
          availableBalance: _ctx.availableBalance,
          overdrawnBalance: _ctx.overdrawnBalance,
          pendingBalance: _ctx.pendingBalance,
          autoTopup: _ctx.autoTopup,
          autoRechargeAmount: _ctx.autoRechargeAmount,
          lowBalanceAmount: _ctx.lowBalanceAmount,
          paymentMethodCardNumber: _ctx.paymentMethodCardNumber,
          paymentMethodCreatedAt: _ctx.paymentMethodCreatedAt,
          paymentMethodSource: _ctx.paymentMethodSource,
          paymentMethodInvoiceRecipientEmail: 
            _ctx.paymentMethodInvoiceRecipientEmail || ''
          ,
          updateAccountBalance: _ctx.updateAccountBalance
        }, null, 8, ["availableBalance", "overdrawnBalance", "pendingBalance", "autoTopup", "autoRechargeAmount", "lowBalanceAmount", "paymentMethodCardNumber", "paymentMethodCreatedAt", "paymentMethodSource", "paymentMethodInvoiceRecipientEmail", "updateAccountBalance"]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_BaseButton, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showBillingHistory())),
                class: _normalizeClass(["px-4 py-1 text-black text-sm font-medium leading-5", 
                  _ctx.isPayoutHistoryVisible
                    ? 'bg-gray-200'
                    : 'bg-gray-100 rounded-md'
                ])
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Billing History ")
                ])),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_BaseButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPayoutHistory())),
                class: _normalizeClass(["px-4 py-1 text-gray-900 text-sm font-medium leading-5", 
                  _ctx.isPayoutHistoryVisible
                    ? 'bg-gray-100 rounded-md'
                    : 'bg-gray-200'
                ])
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Payout History ")
                ])),
                _: 1
              }, 8, ["class"])
            ])
          ]),
          (_ctx.isPayoutHistoryVisible)
            ? (_openBlock(), _createBlock(_component_PayoutsHistory, {
                key: 0,
                handleError: _ctx.handleError
              }, null, 8, ["handleError"]))
            : _createCommentVNode("", true),
          (!_ctx.isPayoutHistoryVisible)
            ? (_openBlock(), _createBlock(_component_InvoiceHistory, {
                key: 1,
                ref: "invoiceHistoryRef",
                handleError: _ctx.handleError
              }, null, 8, ["handleError"]))
            : _createCommentVNode("", true),
          (!_ctx.isPayoutHistoryVisible)
            ? (_openBlock(), _createBlock(_component_CardTransactionHistory, {
                key: 2,
                cardTransactions: _ctx.cardTransactions
              }, null, 8, ["cardTransactions"]))
            : _createCommentVNode("", true)
        ])
      ], 512), [
        [_vShow, _ctx.showCurrentAccountBalance]
      ]),
      _withDirectives(_createVNode(_component_UpdateAccountBalance, {
        accountBalanceId: _ctx.accountBalanceId,
        availableBalance: _ctx.availableBalance,
        autoTopup: _ctx.autoTopup,
        autoRechargeAmount: _ctx.autoRechargeAmount,
        lowBalanceAmount: _ctx.lowBalanceAmount,
        paymentMethodExpirationDate: _ctx.paymentMethodExpirationDate,
        paymentMethodCardNumber: _ctx.paymentMethodCardNumber,
        paymentMethodInvoiceRecipientEmail: _ctx.paymentMethodInvoiceRecipientEmail,
        paymentMethodSource: _ctx.paymentMethodSource,
        paymentMethodId: _ctx.paymentMethodId,
        cancel: _ctx.cancelUpdateAccountBalance,
        onRefetchAccountBalance: _ctx.refetchAccountBalance
      }, null, 8, ["accountBalanceId", "availableBalance", "autoTopup", "autoRechargeAmount", "lowBalanceAmount", "paymentMethodExpirationDate", "paymentMethodCardNumber", "paymentMethodInvoiceRecipientEmail", "paymentMethodSource", "paymentMethodId", "cancel", "onRefetchAccountBalance"]), [
        [_vShow, !_ctx.showCurrentAccountBalance]
      ])
    ])
  ]))
}