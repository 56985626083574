import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/analytics/roi-section.svg'


const _hoisted_1 = { class: "flex flex-wrap w-full justify-between gap-2" }
const _hoisted_2 = { class: "flex flex-col gap-3 flex-1 min-w-[300px]" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "flex flex-wrap w-full gap-4" }
const _hoisted_5 = { class: "flex flex-col w-full" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-col text-left w-full" }
const _hoisted_8 = { class: "text-gray-900 font-sans text-sm font-medium leading-5" }
const _hoisted_9 = { class: "flex flex-col w-full" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "flex flex-col text-left w-full" }
const _hoisted_12 = { class: "text-gray-900 font-sans text-sm font-medium leading-5" }
const _hoisted_13 = { class: "flex flex-col w-full" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "flex flex-col text-left w-full" }
const _hoisted_16 = { class: "whitespace-nowrap font-sans text-sm font-medium leading-5" }
const _hoisted_17 = {
  key: 0,
  class: "text-primary-purple whitespace-nowrap bg-purple-100 py-0.5 px-1 rounded-md font-sans text-xs font-medium leading-5 ml-1"
}
const _hoisted_18 = { class: "flex w-full border border-primary-purple rounded-b-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex" }, [
        _createElementVNode("span", { class: "text-gray-900 text-left font-sans text-lg font-semibold leading-9" }, "Experts Activity")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "flex-1 cursor-pointer min-w-[150px] w-full sm:w-auto",
            role: "button",
            tabindex: "0",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTab(1)))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex px-4 py-5 gap-4 items-center border rounded-lg w-full", 
                  _ctx.selectedTab === 1
                    ? 'border-primary-purple'
                    : 'border-gray-200'
                ])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex justify-center items-center w-12 h-12 rounded-md flex-shrink-0", 
                    _ctx.selectedTab === 1
                      ? 'bg-primary-purple text-white'
                      : 'bg-purple-100 text-primary-purple'
                  ])
                }, [
                  _createElementVNode("div", { innerHTML: _ctx.PERSON_ICON }, null, 8, _hoisted_6)
                ], 2),
                _createElementVNode("div", _hoisted_7, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-gray-500 font-sans text-sm font-medium leading-5" }, [
                    _createTextVNode(" Total "),
                    _createElementVNode("br"),
                    _createTextVNode("Experts ")
                  ], -1)),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.totalExperts), 1)
                ])
              ], 2)
            ])
          ]),
          _createElementVNode("div", {
            class: "flex-1 cursor-pointer min-w-[150px] w-full sm:w-auto",
            role: "button",
            tabindex: "0",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeTab(2)))
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex px-4 py-5 gap-4 items-center border rounded-lg w-full", 
                  _ctx.selectedTab === 2
                    ? 'border-primary-purple'
                    : 'border-gray-200'
                ])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex justify-center items-center w-12 h-12 rounded-md flex-shrink-0", 
                    _ctx.selectedTab === 2
                      ? 'bg-primary-purple text-white'
                      : 'bg-purple-100 text-primary-purple'
                  ])
                }, [
                  _createElementVNode("div", { innerHTML: _ctx.CURSOR_CLICK_ICON }, null, 8, _hoisted_10)
                ], 2),
                _createElementVNode("div", _hoisted_11, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-gray-500 font-sans text-sm font-medium leading-5" }, [
                    _createTextVNode(" Booking System "),
                    _createElementVNode("br"),
                    _createTextVNode("Views ")
                  ], -1)),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.totalDirectoryVisits), 1)
                ])
              ], 2)
            ])
          ]),
          _createElementVNode("div", {
            class: "flex-1 cursor-pointer min-w-[195px] w-full sm:w-auto",
            role: "button",
            tabindex: "0",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeTab(3)))
          }, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex px-4 py-5 gap-4 items-center border rounded-lg w-full", 
                  _ctx.selectedTab === 3
                    ? 'border-primary-purple'
                    : 'border-gray-200'
                ])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex justify-center items-center w-12 h-12 rounded-md flex-shrink-0", 
                    _ctx.selectedTab === 3
                      ? 'bg-primary-purple text-white'
                      : 'bg-purple-100 text-primary-purple'
                  ])
                }, [
                  _createElementVNode("div", { innerHTML: _ctx.PEOPLE_ICON }, null, 8, _hoisted_14)
                ], 2),
                _createElementVNode("div", _hoisted_15, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-gray-500 font-sans text-sm font-medium leading-5" }, [
                    _createTextVNode(" Expert "),
                    _createElementVNode("br"),
                    _createTextVNode("Meetings ")
                  ], -1)),
                  _createElementVNode("span", _hoisted_16, [
                    _createTextVNode(_toDisplayString(_ctx.totalBookings) + " ", 1),
                    (_ctx.totalUpcomingBookings > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Upcoming " + _toDisplayString(_ctx.totalUpcomingBookings), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 2)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          (_openBlock(), _createBlock(_component_Bar, {
            id: "my-chart-id",
            options: _ctx.chartOptions,
            data: _ctx.chartData,
            key: _ctx.graphKey
          }, null, 8, ["options", "data"]))
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"flex flex-1 min-w-[300px] sm:w-auto\"><div class=\"flex flex-col gap-3\"><div class=\"flex\"><span class=\"text-gray-900 text-left font-sans text-lg font-semibold leading-9\">Experts Activity</span></div><div class=\"flex flex-col w-full h-full\"><img src=\"" + _imports_0 + "\" class=\"w-full object-contain\"></div></div></div>", 1))
  ]))
}