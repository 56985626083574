<template>
  <div class="flex flex-wrap items-center gap-2">
    <div v-html="EARNINGS_ICON" class="text-primary-purple"></div>
    <div class="flex flex-col">
      <div class="flex flex-col">
        <span class="text-sm font-medium leading-5 text-zinc-800 text-left">
          Available Balance: ${{
            userStore.availableBalance?.toFixed(2) ?? "0.00"
          }}
        </span>
      </div>
    </div>
    <div class="flex flex-col">
      <BaseButton
        type="button"
        class="flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center"
        @click="addBalance"
      >
        Add Balance
      </BaseButton>
    </div>
  </div>
  <UpdateAccountBalanceModal
    :isVisible="addAccountBalanceModal"
    :cardNumber="cardNumber"
    @update:isVisible="addAccountBalanceModal = $event"
    @updateAccountBalance="handleUpdateAccountBalance"
  />
</template>

<script lang="ts">
import { EARNINGS_ICON } from "@/assets/svg/dashboard/svg-constants";
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";
import UpdateAccountBalanceModal from "@/components/billing/UpdateAccountBalanceModal.vue";

const GET_ACCOUNT_BALANCE = gql`
  query {
    fetchAccountBalance {
      id
      availableBalance
      pendingBalance
      activePaymentMethod {
        paymentMethodId
        source
        invoiceRecipientEmail
        paymentInfo {
          cardNumber
        }
      }
    }
  }
`;

export default defineComponent({
  name: "NavbarAvailableBalance",
  components: { BaseButton, UpdateAccountBalanceModal },

  setup() {
    const userStore = useUserStore();
    const addAccountBalanceModal = ref(false);
    const cardNumber = ref("");
    let intervalId: number | undefined;

    const addBalance = () => {
      console.log("addBalance");
      addAccountBalanceModal.value = true;
    };

    const fetchAccountBalance = async () => {
      const { refetch } = useQuery(GET_ACCOUNT_BALANCE);

      try {
        const response = await refetch();
        const accountData = response?.data.fetchAccountBalance;

        if (accountData) {
          console.log("account balance data ", accountData);
          userStore.setAccountBalance(accountData.availableBalance);
          userStore.setPendingBalance(accountData.pendingBalance);
          userStore.setAccountBalanceId(accountData.id);
          userStore.setPaymentMethod({
            paymentMethodId: accountData.activePaymentMethod.paymentMethodId,
            source: accountData.activePaymentMethod.source,
            invoiceRecipientEmail:
              accountData.activePaymentMethod.invoiceRecipientEmail,
          });
          cardNumber.value =
            accountData.activePaymentMethod?.paymentInfo?.cardNumber;
        }
      } catch (error) {
        console.error("Error fetching account balance: ", error);
      }
    };

    watch(
      () => userStore.updateAccountBalance,
      (newValue) => {
        if (newValue) {
          fetchAccountBalance();
          userStore.setUpdateAccountBalance(null);
        }
      },
      { immediate: true }
    );

    const handleUpdateAccountBalance = () => {
      fetchAccountBalance();
    };

    onMounted(() => {
      setTimeout(() => {
        fetchAccountBalance();
      }, 500);

      intervalId = window.setInterval(fetchAccountBalance, 30000);
    });

    onBeforeUnmount(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    });

    return {
      EARNINGS_ICON,
      addBalance,
      userStore,
      handleUpdateAccountBalance,
      addAccountBalanceModal,
      cardNumber,
    };
  },
});
</script>
