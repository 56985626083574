<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    @click.self="closeModal"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-6 max-w-2xl my-2 w-full relative max-h-[90vh] overflow-y-auto"
    >
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        @click="closeModal"
      >
        ✕
      </button>
      <div class="flex flex-col">
        <div
          class="flex z-10 gap-8 justify-start items-center border-b border-gray-200 self-start mt-8 mx-10 max-md:ml-2.5"
        >
          <div
            class="flex flex-col justify-center self-stretch my-auto text-sm font-medium leading-none text-violet-600 whitespace-nowrap"
          >
            <div
              class="flex gap-2 justify-center items-center self-center px-1 pb-4"
            >
              <div v-html="HTML_ICON" class="text-primary-purple"></div>
              <span class="self-stretch my-auto">HTML</span>
            </div>
            <div class="flex w-full bg-violet-600 min-h-[2px]"></div>
          </div>
        </div>
        <div class="flex flex-col mt-8 max-w-full mx-10">
          <div class="flex flex-col gap-5 max-md:flex-col">
            <div class="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div
                class="flex flex-col grow mt-3 text-sm font-medium leading-5 text-black max-md:mt-7 max-md:max-w-full"
              >
                <pre
                  ref="codeSnippet"
                  class="self-start text-left overflow-x-auto p-4 rounded-md max-w-full break-all whitespace-pre-wrap bg-gray-100 border border-gray-300"
                >
<code class="language-html break-all">
# div should be placed at the bottom of the,
# outer most container of the webpage.

{{ computedCompanyWidgetCode }}

&lt;script src="https://{{ awsBucket }}.s3.us-east-1.amazonaws.com/{{ widgetDirectory }}/bundle.js"&gt;&lt;/script&gt;
&lt;link rel="stylesheet" href="https://{{ awsBucket }}.s3.us-east-1.amazonaws.com/{{ widgetDirectory }}/bundle.css"&gt;
</code>
    </pre>
              </div>
            </div>
            <div class="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <BaseButton
                type="button"
                @click="copyCodeToClipboard"
                class="overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 w-full text-base font-medium text-indigo-700 bg-indigo-50 rounded-md shadow-sm max-md:mt-4"
              >
                {{ buttonText }}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { HTML_ICON } from "@/assets/svg/shared/svg-constants";

export default defineComponent({
  name: "DirectoryCodeModal",
  components: {
    BaseButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    topHeaderBarId: {
      type: String,
      required: true,
    },
    popupModalId: {
      type: String,
      required: true,
    },
    topHeaderBarVisible: {
      type: Boolean,
      required: true,
    },
    popupModalVisible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["closeCodeModal"],
  setup(props, { emit }) {
    const codeSnippet = ref<HTMLElement | null>(null);
    const buttonText = ref("Copy Code");
    const widgetDirectory = process.env.VUE_APP_WIDGET_DIRECTORY;
    const awsBucket = process.env.VUE_APP_S3_COMPANY_WIDGET_BUCKET;

    const copyCodeToClipboard = () => {
      const codeElement = codeSnippet.value?.querySelector("code");
      const codeText = codeElement?.textContent || "";
      if (codeText) {
        navigator.clipboard
          .writeText(codeText)
          .then(() => {
            buttonText.value = "Copied!";
            setTimeout(() => {
              buttonText.value = "Copy Code";
            }, 2000);
          })
          .catch((error) => {
            console.error("Failed to copy the code: ", error);
          });
      } else {
        console.error("Code element not found");
      }
    };

    const closeModal = () => {
      emit("closeCodeModal");
    };

    const computedCompanyWidgetCode = computed(() => {
      const toHeaderBarId = props.topHeaderBarVisible
        ? props.topHeaderBarId
        : "";
      const popupModalId = props.popupModalVisible ? props.popupModalId : "";

      const widgetCode =
        toHeaderBarId && popupModalId
          ? `${toHeaderBarId}:${popupModalId}`
          : `${toHeaderBarId}${popupModalId}`;

      return `<div id="company-widget-container" data-widget-id="${widgetCode}"></div>`;
    });

    return {
      closeModal,
      copyCodeToClipboard,
      buttonText,
      HTML_ICON,
      codeSnippet,
      computedCompanyWidgetCode,
      widgetDirectory,
      awsBucket,
    };
  },
});
</script>
