import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-col h-screen" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = { class: "flex flex-col w-full border-b" }
const _hoisted_4 = { class: "flex flex-col items-start gap-6 w-full p-6" }
const _hoisted_5 = { class: "flex justify-center mb-4 w-full" }
const _hoisted_6 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_7 = { class: "flex w-full" }
const _hoisted_8 = { class: "flex w-full" }
const _hoisted_9 = { class: "flex w-full" }
const _hoisted_10 = { class: "flex w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_DateFilters = _resolveComponent("DateFilters")!
  const _component_AnalyticsSummary = _resolveComponent("AnalyticsSummary")!
  const _component_AnalyticsGraphs = _resolveComponent("AnalyticsGraphs")!
  const _component_ExpertMeetings = _resolveComponent("ExpertMeetings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("header", _hoisted_3, [
      _createVNode(_component_Navbar, { activeTab: 2 })
    ]),
    _createElementVNode("main", _hoisted_4, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col" }, [
        _createElementVNode("span", { class: "text-3xl font-semibold leading-9 text-left text-zinc-800" }, "Analytics")
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Error Icon",
                  class: "w-4 h-4"
                })
              ], -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_DateFilters, {
          applyFilter: _ctx.applyFilter,
          handleError: _ctx.handleError
        }, null, 8, ["applyFilter", "handleError"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_AnalyticsSummary, { totalSpend: _ctx.totalSpend }, null, 8, ["totalSpend"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(), _createBlock(_component_AnalyticsGraphs, {
          key: _ctx.graphKey,
          totalExperts: _ctx.totalExperts,
          totalBookings: _ctx.totalBookings,
          totalDirectoryVisits: _ctx.totalDirectoryVisits,
          totalUpcomingBookings: _ctx.totalUpcomingBookings,
          bookingsByDate: _ctx.bookingsByDate,
          expertsByDate: _ctx.expertsByDate,
          directoryVisitsByDate: _ctx.directoryVisitsByDate
        }, null, 8, ["totalExperts", "totalBookings", "totalDirectoryVisits", "totalUpcomingBookings", "bookingsByDate", "expertsByDate", "directoryVisitsByDate"]))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_ExpertMeetings, { bookings: _ctx.bookings }, null, 8, ["bookings"])
      ])
    ])
  ]))
}