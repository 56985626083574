import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center mb-2 bg-white rounded-xl border border-gray-300 border-solid" }
const _hoisted_2 = { class: "flex flex-col justify-center w-full rounded-xl" }
const _hoisted_3 = { class: "flex justify-between items-center px-6 py-5 rounded-xl w-full bg-slate-50" }
const _hoisted_4 = { class: "flex items-center gap-4 py-2 px-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col w-full px-6 pb-5 rounded-xl max-md:px-5",
  ref: "collapsible"
}
const _hoisted_7 = { class: "flex flex-col mb-4 mt-4 text-left pb-4" }
const _hoisted_8 = { class: "flex flex-col mb-2" }
const _hoisted_9 = { class: "flex flex-col mb-1" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = { class: "flex flex-col mt-1" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "flex flex-row mt-3 gap-2" }
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "flex flex-col mb-1" }
const _hoisted_17 = { class: "flex w-32 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1" }
const _hoisted_18 = { class: "flex flex-col mb-1" }
const _hoisted_19 = { class: "flex w-32 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1" }
const _hoisted_20 = { class: "flex flex-col mt-1" }
const _hoisted_21 = { class: "flex w-32 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1" }
const _hoisted_22 = { class: "flex flex-col mt-1" }
const _hoisted_23 = { class: "flex w-32 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[30] || (_cache[30] = _createElementVNode("h3", { class: "text-sm font-semibold text-black" }, "Top Header Bar", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: _normalizeClass(["flex items-center w-12 h-6 rounded-xl transition-colors duration-300", {
              'bg-violet-600': _ctx.isVisible,
              'bg-gray-200': !_ctx.isVisible,
            }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleVisibility())),
            "aria-label": "Toggle button"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform", {
                'translate-x-6': _ctx.isVisible,
                'translate-x-0.5': !_ctx.isVisible,
              }])
            }, null, 2)
          ], 2),
          _createElementVNode("button", {
            class: "w-5",
            "aria-label": "Toggle top header bar",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args)))
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.isCollapsed ? _ctx.ARROW_DOWN : _ctx.ARROW_UP
            }, null, 8, _hoisted_5)
          ])
        ])
      ]),
      _createVNode(_Transition, {
        onBeforeEnter: _ctx.beforeEnter,
        onEnter: _ctx.enter,
        onLeave: _ctx.leave
      }, {
        default: _withCtx(() => [
          (!_ctx.isCollapsed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "headerText",
                      text: "Header Text",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal"
                    }),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.topHeaderText) = $event)),
                      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleHeaderTextChange($event))),
                      placeholder: "Type your text here...",
                      class: _normalizeClass(["w-full h-12 border rounded-md p-2 resize-none focus:outline-none", {
                  'border-red-500': _ctx.headerTextWarning,
                  'border-gray-300': !_ctx.headerTextWarning,
                }]),
                      rows: "2"
                    }, null, 34), [
                      [_vModelText, _ctx.topHeaderText]
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["text-xs mt-1 text-gray-400", { 'text-red-500': _ctx.headerTextWarning }])
                    }, _toDisplayString(_ctx.topHeaderText.length) + " / 80 ", 3)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "buttonText",
                      text: "Button Text",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal"
                    }),
                    _createVNode(_component_BaseInput, {
                      id: "headerButtonText",
                      modelValue: _ctx.headerButtonText,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.headerButtonText) = $event)),
                      type: "text",
                      onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleButtonTextChange($event))),
                      placeholder: "Type your text here...",
                      className: {
                  'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                  'border-red-500': _ctx.headerButtonTextWarning,
                  'border-gray-300': !_ctx.headerButtonTextWarning,
                }
                    }, null, 8, ["modelValue", "className"]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["text-xs mt-1 text-gray-400", { 'text-red-500': _ctx.headerButtonTextWarning }])
                    }, _toDisplayString(_ctx.headerButtonText.length) + " / 20 ", 3)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "expertsDirectoryUrl",
                      text: "Button Link",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal"
                    }),
                    _createVNode(_component_BaseInput, {
                      id: "expertsDirectoryUrl",
                      modelValue: _ctx.expertsDirectoryUrl,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.expertsDirectoryUrl) = $event)),
                      type: "text",
                      onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleExpertsDirectoryUrlChange($event))),
                      placeholder: "Type your text here...",
                      className: {
                  'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                  'border-red-500': _ctx.expertsDirectoryUrlWarning,
                  'border-gray-300': !_ctx.expertsDirectoryUrlWarning,
                }
                    }, null, 8, ["modelValue", "className"]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["text-xs mt-1 text-gray-400", { 'text-red-500': _ctx.expertsDirectoryUrlWarning }])
                    }, _toDisplayString(_ctx.expertsDirectoryUrl.length) + " / 60 ", 3)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "selectedFontFamily",
                      text: "Font",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal"
                    }),
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedFontFamily) = $event)),
                      onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.applyFontFamily($event))),
                      class: "border rounded px-2 py-1"
                    }, [
                      _cache[31] || (_cache[31] = _createElementVNode("option", { value: "" }, "Select Font", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fonts, (font) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: font,
                          value: font
                        }, _toDisplayString(font), 9, _hoisted_12))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.selectedFontFamily]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedBoldness) = $event)),
                      onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.applyBoldness($event))),
                      class: "border rounded py-1 w-1/2"
                    }, [
                      _cache[32] || (_cache[32] = _createElementVNode("option", { value: "" }, "Select Boldness", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fontWeights, (weight, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: index,
                          value: weight.value
                        }, _toDisplayString(weight.label), 9, _hoisted_14))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.selectedBoldness]
                    ]),
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedFontSize) = $event)),
                      onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.applyFontSize($event))),
                      class: "border rounded py-1 w-1/2"
                    }, [
                      _cache[33] || (_cache[33] = _createElementVNode("option", { value: "" }, "Select Font Size", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fontSizes, (size) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: size,
                          value: size
                        }, _toDisplayString(size), 9, _hoisted_15))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.selectedFontSize]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "textColor",
                      text: "Button Text Color",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal mt-1"
                    }),
                    _createElementVNode("div", _hoisted_17, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.textColor) = $event)),
                        type: "color",
                        onInput: _cache[15] || (_cache[15] = ($event: any) => (_ctx.applyTextColor($event))),
                        class: "w-7 h-7 shrink-0"
                      }, null, 544), [
                        [_vModelText, _ctx.textColor]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.textColor) = $event)),
                        onInput: _cache[17] || (_cache[17] = ($event: any) => (_ctx.applyTextColor($event))),
                        class: "ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                      }, null, 544), [
                        [_vModelText, _ctx.textColor]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "buttonBackgroundColor",
                      text: "Button Background Color",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal mt-1"
                    }),
                    _createElementVNode("div", _hoisted_19, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.buttonBackgroundColor) = $event)),
                        type: "color",
                        onInput: _cache[19] || (_cache[19] = ($event: any) => (_ctx.applyButtonBackgroundColor($event))),
                        class: "w-7 h-7 shrink-0"
                      }, null, 544), [
                        [_vModelText, _ctx.buttonBackgroundColor]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.buttonBackgroundColor) = $event)),
                        onInput: _cache[21] || (_cache[21] = ($event: any) => (_ctx.applyButtonBackgroundColor($event))),
                        class: "ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                      }, null, 544), [
                        [_vModelText, _ctx.buttonBackgroundColor]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "headerBackgroundColor",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal mt-5",
                      text: "Header Background Color"
                    }),
                    _createElementVNode("div", _hoisted_21, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.headerBackgroundColor) = $event)),
                        type: "color",
                        onInput: _cache[23] || (_cache[23] = ($event: any) => (_ctx.applyHeaderBackgroundColor($event))),
                        class: "w-7 h-7 shrink-0"
                      }, null, 544), [
                        [_vModelText, _ctx.headerBackgroundColor]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.headerBackgroundColor) = $event)),
                        onInput: _cache[25] || (_cache[25] = ($event: any) => (_ctx.applyHeaderBackgroundColor($event))),
                        class: "ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                      }, null, 544), [
                        [_vModelText, _ctx.headerBackgroundColor]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "headerTextColor",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal mt-5",
                      text: "Header Text Color"
                    }),
                    _createElementVNode("div", _hoisted_23, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.headerTextColor) = $event)),
                        type: "color",
                        onInput: _cache[27] || (_cache[27] = ($event: any) => (_ctx.applyHeaderTextColor($event))),
                        class: "w-7 h-7 shrink-0"
                      }, null, 544), [
                        [_vModelText, _ctx.headerTextColor]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.headerTextColor) = $event)),
                        onInput: _cache[29] || (_cache[29] = ($event: any) => (_ctx.applyHeaderTextColor($event))),
                        class: "ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                      }, null, 544), [
                        [_vModelText, _ctx.headerTextColor]
                      ])
                    ])
                  ])
                ])
              ], 512))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onBeforeEnter", "onEnter", "onLeave"])
    ])
  ]))
}