<template>
  <div
    class="flex flex-col justify-center mb-2 bg-white rounded-xl border border-gray-300 border-solid"
  >
    <div class="flex flex-col justify-center w-full rounded-xl">
      <div
        class="flex justify-between items-center px-6 py-5 rounded-xl w-full bg-slate-50"
      >
        <!-- Header aligned at the start -->
        <h3 class="text-sm font-semibold text-black">Corner Widget</h3>

        <!-- Toggle button and additional button side by side at the end -->
        <div class="flex items-center gap-4 py-2 px-2">
          <!-- Toggle Button -->
          <button
            class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
            :class="{
              'bg-violet-600': isVisible,
              'bg-gray-200': !isVisible,
            }"
            @click="toggleVisibility()"
            aria-label="Toggle button"
          >
            <span
              class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
              :class="{
                'translate-x-6': isVisible,
                'translate-x-0.5': !isVisible,
              }"
            ></span>
          </button>

          <!-- Button for toggle top header bar -->
          <button
            class="w-5"
            aria-label="Toggle top header bar"
            @click="toggleCollapse"
          >
            <div v-html="isCollapsed ? ARROW_DOWN : ARROW_UP"></div>
          </button>
        </div>
      </div>

      <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div
          class="flex flex-col w-full px-6 pb-5 rounded-xl max-md:px-5"
          v-if="!isCollapsed"
          ref="collapsible"
        >
          <div class="flex flex-col mb-4 mt-4 text-left pb-4 gap-4">
            <div class="flex flex-col">
              <BaseLabel
                forAttr="bodyText"
                text="Corner Widget Text"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <textarea
                v-model="bodyText"
                @input="handleBodyTextChange($event)"
                placeholder="Type your text here..."
                class="w-full h-12 border rounded-md p-2 resize-none focus:outline-none"
                :class="{
                  'border-red-500': bodyTextWarning,
                  'border-gray-300': !bodyTextWarning,
                }"
                rows="2"
              ></textarea>
              <span
                class="text-xs mt-1 text-gray-400"
                :class="{ 'text-red-500': bodyTextWarning }"
              >
                {{ bodyText.length }} / 80
              </span>
            </div>

            <div class="flex flex-col">
              <BaseLabel
                forAttr="buttonText"
                text="Button Text"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <BaseInput
                id="buttonText"
                v-model="buttonText"
                type="text"
                @input="handleButtonTextChange($event)"
                placeholder="Type your text here..."
                :className="{
                  'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                  'border-red-500': buttonTextWarning,
                  'border-gray-300': !buttonTextWarning,
                }"
              />
              <span
                class="text-xs mt-1 text-gray-400"
                :class="{ 'text-red-500': buttonTextWarning }"
              >
                {{ buttonText.length }} / 20
              </span>
            </div>

            <div class="flex flex-col">
              <BaseLabel
                forAttr="expertsDirectoryUrl"
                text="Button Link"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <BaseInput
                id="expertsDirectoryUrl"
                v-model="expertsDirectoryUrl"
                type="text"
                @input="handleExpertsDirectoryUrlChange($event)"
                placeholder="Type your text here..."
                :className="{
                  'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                  'border-red-500': expertsDirectoryUrlWarning,
                  'border-gray-300': !expertsDirectoryUrlWarning,
                }"
              />
              <span
                class="text-xs mt-1 text-gray-400"
                :class="{ 'text-red-500': expertsDirectoryUrlWarning }"
              >
                {{ expertsDirectoryUrl.length }} / 60
              </span>
            </div>

            <div class="flex flex-col">
              <BaseLabel
                forAttr="selectedFontFamily"
                text="Font"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <select
                v-model="selectedFontFamily"
                @change="applyFontFamily($event)"
                class="border rounded px-2 py-1"
              >
                <option value="">Select Font</option>
                <option v-for="font in fonts" :key="font" :value="font">
                  {{ font }}
                </option>
              </select>
            </div>

            <div class="flex flex-row gap-2">
              <select
                v-model="selectedBoldness"
                @change="applyBoldness($event)"
                class="border rounded py-1 w-1/2"
              >
                <option value="">Select Boldness</option>
                <option
                  v-for="(weight, index) in fontWeights"
                  :key="index"
                  :value="weight.value"
                >
                  {{ weight.label }}
                </option>
              </select>

              <select
                v-model="selectedFontSize"
                @change="applyFontSize($event)"
                class="border rounded py-1 w-1/2"
              >
                <option value="">Select Font Size</option>
                <option v-for="size in fontSizes" :key="size" :value="size">
                  {{ size }}
                </option>
              </select>
            </div>

            <div class="flex flex-col gap-3">
              <div class="flex flex-col">
                <BaseLabel
                  forAttr="buttonTextColor"
                  text="Button Text Color"
                  class="font-inter text-sm font-medium leading-normal tracking-normal"
                />

                <div
                  class="flex w-32 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
                >
                  <input
                    v-model="buttonTextColor"
                    type="color"
                    @input="applyButtonTextColor($event)"
                    class="w-7 h-7 shrink-0"
                  />
                  <input
                    v-model="buttonTextColor"
                    @input="applyButtonTextColor($event)"
                    class="ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                  />
                </div>
              </div>

              <div class="flex flex-col">
                <!-- Background Color Selector -->
                <BaseLabel
                  forAttr="buttonBackgroundColor"
                  class="font-inter text-sm font-medium leading-normal tracking-normal"
                  text="Button Background Color"
                />

                <div
                  class="flex w-32 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
                >
                  <input
                    v-model="buttonBackgroundColor"
                    type="color"
                    @input="applyButtonBackgroundColor($event)"
                    class="w-7 h-7 shrink-0"
                  />
                  <input
                    v-model="buttonBackgroundColor"
                    @input="applyButtonBackgroundColor($event)"
                    class="ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                  />
                </div>
              </div>

              <div class="flex flex-col">
                <BaseLabel
                  forAttr="textColor"
                  class="font-inter text-sm font-medium leading-normal tracking-normal"
                  text="Text Color"
                />

                <div
                  class="flex w-32 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
                >
                  <input
                    v-model="textColor"
                    type="color"
                    @input="applyTextColor($event)"
                    class="w-7 h-7 shrink-0"
                  />
                  <input
                    v-model="textColor"
                    @input="applyTextColor($event)"
                    class="ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                  />
                </div>
              </div>

              <div class="flex flex-col">
                <BaseLabel
                  forAttr="bannerBackgroundColor"
                  class="font-inter text-sm font-medium leading-normal tracking-normal"
                  text="Background Color"
                />

                <div
                  class="flex w-32 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
                >
                  <input
                    v-model="bannerBackgroundColor"
                    type="color"
                    @input="applyBannerBackgroundColor($event)"
                    class="w-7 h-7 shrink-0"
                  />
                  <input
                    v-model="bannerBackgroundColor"
                    @input="applyBannerBackgroundColor($event)"
                    class="ml-2 bg-transparent border-none focus:outline-none text-sm text-gray-800"
                  />
                </div>
              </div>

              <div class="flex flex-col">
                <div class="flex flex-row justify-between">
                  <div class="flex flex-col justify-center items-center">
                    <span
                      class="font-inter text-sm font-medium leading-normal tracking-normal"
                      >Orientation</span
                    >
                  </div>
                  <div class="flex flex-row gap-2">
                    <BaseButton
                      type="button"
                      @click="applyLeftOrientation(true)"
                      class="flex flex-col justify-center items-start p-4 w-full text-sm font-medium leading-none text-indigo-600 bg-gray-50 text-left"
                      :class="{
                        'border border-primary-purple rounded-md':
                          isLeftOrientation,
                        '': !isLeftOrientation,
                      }"
                    >
                      <span class="self-stretch">Left</span>
                    </BaseButton>
                    <BaseButton
                      type="button"
                      @click="applyLeftOrientation(false)"
                      class="flex flex-col justify-center items-start p-4 w-full text-sm font-medium leading-none text-indigo-600 bg-gray-50 text-left"
                      :class="{
                        'border border-primary-purple rounded-md':
                          !isLeftOrientation,
                        '': isLeftOrientation,
                      }"
                    >
                      <span class="self-stretch">Right</span>
                    </BaseButton>
                  </div>
                </div>
              </div>

              <div class="flex flex-col gap-2">
                <div class="flex flex-col items-start">
                  <span
                    class="font-inter text-sm font-medium leading-normal tracking-normal"
                  >
                    Hide corner widget when viewing on mobile
                    <span class="inline relative group ml-1">
                      <div
                        v-html="INFO_ICON"
                        class="inline-block w-4 h-4"
                      ></div>
                      <div
                        class="absolute hidden group-hover:flex items-center justify-center z-50 text-xs text-white bg-gray-800 rounded-md p-2 w-44 top-full mt-1 left-1/2 -translate-x-1/2"
                      >
                        This option will hide the corner widget on smaller
                        screens.
                      </div>
                    </span>
                  </span>
                </div>

                <button
                  class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
                  :class="{
                    'bg-violet-600': isVisibleOnMobile,
                    'bg-gray-200': !isVisibleOnMobile,
                  }"
                  @click="toggleVisibilityOnMobile()"
                  aria-label="Toggle button"
                >
                  <span
                    class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
                    :class="{
                      'translate-x-6': isVisibleOnMobile,
                      'translate-x-0.5': !isVisibleOnMobile,
                    }"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, Ref, ref } from "vue";
import { useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import {
  ARROW_DOWN,
  ARROW_UP,
  INFO_ICON,
} from "@/assets/svg/shared/svg-constants";
import { fontFamilies } from "@/data/expert-program/font-families";
import { useTiptapMarks } from "@/composables/useTiptapMarks";

const {
  Boldness,
  FontFamily,
  FontSize,
  TextColor,
  ButtonBackgroundColor,
  BannerBackgroundColor,
} = useTiptapMarks();

export default defineComponent({
  name: "TopHeaderBarDesign",
  components: {
    BaseLabel,
    BaseInput,
    BaseButton,
  },
  props: {
    fontFamily: {
      type: String,
      default: "",
    },
    boldness: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
    popupButtonTextColor: {
      type: String,
      default: "",
    },
    popupButtonText: {
      type: String,
      default: "",
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    popupHeadingText: {
      type: String,
      default: "",
    },
    popupBodyText: {
      type: String,
      default: "",
    },
    popupButtonBackgroundColor: {
      type: String,
      default: "",
    },
    popupTextColor: {
      type: String,
      default: "",
    },
    popupBannerBackgroundColor: {
      type: String,
      default: "",
    },
    popupExpertsDirectoryUrl: {
      type: String,
      default: "",
    },
    popupLeftOrientation: {
      type: Boolean,
      default: true,
    },
    popupMobileVisibility: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "toggleVisibility",
    "fontFamilyChanged",
    "boldnessChanged",
    "fontSizeChanged",
    "buttonTextColorChanged",
    "buttonTextChanged",
    "headingTextChanged",
    "bodyTextChanged",
    "buttonBackgroundColorChanged",
    "textColorChanged",
    "bannerBackgroundColorChanged",
    "expertsDirectoryUrlChanged",
    "leftOrientationChanged",
    "mobileVisibilityChanged",
  ],
  setup(props, { emit }) {
    const editor = useEditor({
      extensions: [
        StarterKit,
        TextStyle,
        FontFamily,
        FontSize,
        TextColor,
        ButtonBackgroundColor,
        BannerBackgroundColor,
        TextColor,
        Boldness,
      ],
    });

    const fonts = fontFamilies;

    const fontSizes = [
      "12",
      "14",
      "16",
      "18",
      // "20",
      // "24",
      // "28",
      // "32",
      // "36",
      // "40",
    ];

    const fontWeights = [
      // { value: "100", label: "Thin" },
      // { value: "200", label: "Extra Light" },
      // { value: "300", label: "Light" },
      { value: "400", label: "Regular" },
      // { value: "500", label: "Medium" },
      // { value: "600", label: "Semi-Bold" },
      { value: "700", label: "Bold" },
      // { value: "800", label: "Extra Bold" },
    ];

    const selectedFontFamily = ref(props.fontFamily);
    const selectedFontSize = ref(props.fontSize);
    const selectedBoldness = ref(props.boldness);

    const isCollapsed = ref(true);
    const bodyText = ref(props.popupBodyText);
    const bodyTextWarning = ref(false);
    const buttonText = ref(props.popupButtonText);
    const buttonTextWarning = ref(false);
    const buttonTextColor = ref(props.popupButtonTextColor);
    const buttonBackgroundColor = ref(props.popupButtonBackgroundColor);
    const textColor = ref(props.popupTextColor);
    const bannerBackgroundColor = ref(props.popupBannerBackgroundColor);
    const expertsDirectoryUrl = ref(props.popupExpertsDirectoryUrl);
    const expertsDirectoryUrlWarning = ref(false);
    const isLeftOrientation = ref(props.popupLeftOrientation);
    const isVisibleOnMobile = ref(props.popupMobileVisibility);

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const applyFontFamily = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const font = target.value;
      selectedFontFamily.value = font;
      emit("fontFamilyChanged", font);
      if (font) {
        editor.value
          ?.chain()
          .focus()
          .setMark("fontFamily", { fontFamily: font })
          .run();
      }
    };

    const applyFontSize = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const size = target.value;
      selectedFontSize.value = size;
      emit("fontSizeChanged", size);
      if (size) {
        editor.value
          ?.chain()
          .focus()
          .setMark("fontSize", { fontSize: size })
          .run();
      }
    };

    const applyBoldness = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const boldness = target.value;
      selectedBoldness.value = boldness;
      emit("boldnessChanged", boldness);
      if (boldness) {
        editor.value?.chain().focus().setMark("boldness", { boldness }).run();
      }
    };

    const handleBodyTextChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 80) {
        alertUser(bodyTextWarning);
      }
      bodyText.value = target.value.slice(0, 80);
      emit("bodyTextChanged", bodyText.value);
    };

    const handleButtonTextChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 20) {
        alertUser(buttonTextWarning);
      }
      buttonText.value = target.value.slice(0, 20);
      emit("buttonTextChanged", buttonText.value);
    };

    const handleExpertsDirectoryUrlChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 60) {
        alertUser(expertsDirectoryUrlWarning);
      }
      expertsDirectoryUrl.value = target.value.slice(0, 60);
      emit("expertsDirectoryUrlChanged", expertsDirectoryUrl.value);
    };

    const applyButtonTextColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      buttonTextColor.value = target.value;
      emit("buttonTextColorChanged", buttonTextColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("textColor", { color: buttonTextColor.value })
        .run();
    };

    const applyButtonBackgroundColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      buttonBackgroundColor.value = target.value;
      emit("buttonBackgroundColorChanged", buttonBackgroundColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("buttonBackgroundColor", {
          backgroundColor: buttonBackgroundColor.value,
        })
        .run();
    };

    const applyTextColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      textColor.value = target.value;
      emit("textColorChanged", textColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("textColor", { color: textColor.value })
        .run();
    };

    const applyBannerBackgroundColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      bannerBackgroundColor.value = target.value;
      emit("bannerBackgroundColorChanged", bannerBackgroundColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("bannerBackgroundColor", {
          backgroundColor: bannerBackgroundColor.value,
        })
        .run();
    };

    const applyLeftOrientation = (isLeft: boolean) => {
      isLeftOrientation.value = isLeft;
      emit("leftOrientationChanged", isLeft);
    };

    const toggleVisibilityOnMobile = () => {
      isVisibleOnMobile.value = !isVisibleOnMobile.value;
      emit("mobileVisibilityChanged", isVisibleOnMobile.value);
    };

    const alertUser = (warningState: Ref<boolean>) => {
      warningState.value = true;

      setTimeout(() => {
        warningState.value = false;
      }, 1000);
    };

    const beforeEnter = (el: Element) => {
      const target = el as HTMLElement;
      target.style.height = "0";
      target.style.opacity = "0";
    };

    const enter = (el: Element) => {
      const target = el as HTMLElement;
      const height = target.scrollHeight;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = height + "px";
      target.style.opacity = "1";
    };

    const leave = (el: Element) => {
      const target = el as HTMLElement;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = "0";
      target.style.opacity = "0";
    };

    const toggleVisibility = () => {
      // isVisible.value = !isVisible.value;
      emit("toggleVisibility", !props.isVisible);
      if (!props.isVisible === false && isCollapsed.value === false) {
        toggleCollapse();
      } else if (props.isVisible === false && isCollapsed.value === true) {
        toggleCollapse();
      }
    };

    onBeforeUnmount(() => {
      editor.value?.destroy();
    });

    return {
      editor,
      applyFontFamily,
      applyFontSize,
      applyBoldness,
      fonts,
      fontSizes,
      fontWeights,
      selectedFontSize,
      selectedFontFamily,
      selectedBoldness,
      ARROW_DOWN,
      ARROW_UP,
      INFO_ICON,
      isCollapsed,
      toggleCollapse,
      beforeEnter,
      enter,
      leave,
      toggleVisibility,
      toggleVisibilityOnMobile,
      isVisibleOnMobile,
      bodyText,
      bodyTextWarning,
      handleBodyTextChange,
      buttonText,
      handleButtonTextChange,
      buttonTextWarning,
      buttonTextColor,
      applyButtonTextColor,
      buttonBackgroundColor,
      applyButtonBackgroundColor,
      textColor,
      applyTextColor,
      bannerBackgroundColor,
      applyBannerBackgroundColor,
      expertsDirectoryUrl,
      expertsDirectoryUrlWarning,
      handleExpertsDirectoryUrlChange,
      isLeftOrientation,
      applyLeftOrientation,
      // isVisible,
    };
  },
});
</script>
