<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex justify-end bg-black bg-opacity-50 z-50 overflow-hidden"
  >
    <div
      class="bg-white w-1/3 h-full p-6 shadow-lg transform transition-transform ease-in-out duration-300 flex flex-col"
    >
      <!-- Modal Content (scrollable) -->
      <div class="flex-1 overflow-y-auto pr-4">
        <div class="flex flex-col">
          <div class="flex justify-end">
            <BaseButton
              type="button"
              @click="closeModal"
              class="mb-4 text-gray-500 justify-end hover:text-gray-700"
            >
              X
            </BaseButton>
          </div>

          <!-- Error Message -->
          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <img
                  src="../../../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p class="text-sm text-left">{{ errorMessage }}</p>
            </div>
          </div>

          <!-- Profile Section -->
          <div class="flex flex-col gap-2 text-left font-inter">
            <!-- Profile Picture -->
            <div
              v-if="profilePicture"
              class="flex w-12 h-12 overflow-hidden rounded-full"
            >
              <img
                loading="lazy"
                :src="profilePictureUrl"
                alt="User profile picture"
                class="w-full h-full object-cover"
              />
            </div>
            <div
              v-else
              class="flex items-center justify-center w-12 h-12 bg-primary-purple text-white rounded-full"
            >
              {{ initials() }}
            </div>

            <!-- User Info -->
            <div class="flex flex-col">
              <div class="text-2xl font-bold">
                {{ expert.firstName }} {{ expert.lastName }}
              </div>
              <div class="text-sm text-gray-500">{{ expert.email }}</div>
            </div>

            <!-- Program Name -->
            <div class="flex flex-col border-b border-gray-200 pb-4 gap-2">
              <BaseLabel
                forAttr="softwareName"
                text="Software Name"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ programName }}</div>
            </div>

            <!-- Expertise -->
            <div class="flex flex-col border-b border-gray-200 pb-4 gap-2">
              <BaseLabel
                forAttr="expertise"
                text="Expertise"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="flex flex-row gap-1 space-x-4">
                <div
                  v-for="index in 5"
                  :key="index"
                  class="w-3 h-3 rounded-full"
                  :class="
                    index <= expertise
                      ? 'bg-primary-purple'
                      : 'bg-white border-2 border-gray-500'
                  "
                ></div>
              </div>
            </div>

            <!-- Expertise -->
            <div class="flex flex-col border-b border-gray-200 pb-4 gap-2">
              <BaseLabel
                forAttr="experience"
                text="Experience"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="flex flex-row gap-1 space-x-4">
                <div
                  v-for="index in 5"
                  :key="index"
                  class="w-3 h-3 rounded-full"
                  :class="
                    index <= experience
                      ? 'bg-primary-purple'
                      : 'bg-white border-2 border-gray-500'
                  "
                ></div>
              </div>
            </div>

            <!-- Uploaded Video -->
            <div class="flex flex-col">
              <div class="text-black text-sm font-medium leading-5 text-left">
                Uploaded Video
              </div>
              <div class="relative">
                <video
                  ref="video"
                  class="w-full h-auto border border-gray-300"
                  controls
                  type="video/mp4"
                  @canplay="handleCanPlay"
                  @loadstart="handleLoadStart"
                  :key="introductionVideo"
                  :src="introductionVideo || ''"
                ></video>
                <div
                  v-if="isVideoLoading"
                  class="absolute inset-0 flex items-center justify-center bg-gray-800 text-white text-lg font-semibold z-10"
                >
                  Loading video...
                </div>
              </div>
            </div>

            <!-- Additional Info -->
            <div class="flex flex-col border-b border-gray-200 pb-4">
              <BaseLabel
                forAttr="title"
                text="Title"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ title }}</div>
            </div>
            <div class="flex flex-col border-b border-gray-200 pb-4">
              <BaseLabel
                forAttr="companyName"
                text="Company Name"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ companyName }}</div>
            </div>
            <div class="flex flex-col border-b border-gray-200 pb-4">
              <BaseLabel
                forAttr="country"
                text="Country"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ country }}</div>
            </div>
            <div class="flex flex-col border-b border-gray-200 pb-4">
              <BaseLabel
                forAttr="state"
                text="State"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ state }}</div>
            </div>
            <div class="flex flex-col border-b border-gray-200 pb-4">
              <BaseLabel
                forAttr="industry"
                text="Industry"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ industry }}</div>
            </div>
            <div class="flex flex-col border-b border-gray-200 pb-4">
              <BaseLabel
                forAttr="linkedinProfile"
                text="LinkedIn Profile"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ linkedinProfile }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Buttons -->
      <div
        class="flex justify-end gap-2 border-t border-gray-200 pt-4 bg-white sticky bottom-0"
      >
        <BaseButton
          type="button"
          @click="handleReject"
          class="bg-white text-black rounded-md border border-gray-300 px-4 py-2"
        >
          Reject
        </BaseButton>
        <BaseButton
          type="button"
          @click="handleAccept"
          class="bg-primary-purple text-white rounded-md px-4 py-2"
        >
          Approve
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { ReviewEntryExpert } from "@/types/dashboard-types";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";

const GET_EXPERT_USER_PROGRAM_PROFILE_QUERY = gql`
  query getCompanyExpertUserProgramProfile($userId: ID!, $programId: ID!) {
    getCompanyExpertUserProgramProfile(userId: $userId, programId: $programId) {
      expertise
      experience
      introductionVideo
      programName
      user {
        country
        state
        industry
        profile_picture
        linkedin_profile
        title
        companyName
      }
    }
  }
`;

const CHANGE_EXPERT_JOIN_PROGRAM_REQUEST_STATUS = gql`
  mutation handleJoinProgramRequestStatus(
    $input: HandleJoinProgramRequestStatusInput!
  ) {
    handleJoinProgramRequestStatus(input: $input) {
      error
      success
    }
  }
`;

export default defineComponent({
  name: "ReviewEnteryModal",
  components: {
    BaseButton,
    BaseLabel,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    expert: {
      type: Object as PropType<ReviewEntryExpert>,
      required: true,
    },
    requestId: {
      type: String,
      required: true,
    },
    onClose: Function as PropType<() => void>,
  },
  emits: ["refreshRequests"],
  setup(props, { emit }) {
    const summary = ref<string>("");
    const introductionVideo = ref<string>("");
    const profilePicture = ref<string>("");
    const country = ref<string>("");
    const state = ref<string>("");
    const industry = ref<string>("");
    const aboutMe = ref<string>("");
    const expertise = ref<number>(1);
    const experience = ref<number>(1);
    const title = ref<string>("");
    const companyName = ref<string>("");
    const linkedinProfile = ref<string>("");
    const programName = ref<string>("");
    const userStore = useUserStore();
    const errorMessage = ref<string>("");
    const isVideoLoading = ref<boolean>(false);
    // const requestId = ref<string>("");

    const {
      mutate: changeExpertJoinProgramRequestStatus,
      onDone: changeExpertJoinProgramRequestStatusDone,
      onError: changeExpertJoinProgramRequestStatusError,
    } = useMutation(CHANGE_EXPERT_JOIN_PROGRAM_REQUEST_STATUS);

    const closeModal = () => {
      if (props.onClose) props.onClose();
    };

    const handleCanPlay = () => {
      isVideoLoading.value = false;
    };

    const handleLoadStart = () => {
      isVideoLoading.value = true;
    };

    const fetchEntryData = () => {
      console.log("calling fetchEntryData with epxer id ", props?.expert?.id);
      const { onResult: onResultEntryData, onError: onErrorEntryData } =
        useQuery(
          GET_EXPERT_USER_PROGRAM_PROFILE_QUERY,
          {
            userId: props?.expert?.id,
            programId: userStore.selectedProgram?.id,
          },
          { fetchPolicy: "network-only" }
        );

      onResultEntryData((result) => {
        if (result.data) {
          console.log("result.data on fetchEntryData", result.data);
          summary.value =
            result.data.getCompanyExpertUserProgramProfile.summary;
          programName.value =
            result.data.getCompanyExpertUserProgramProfile.programName;
          introductionVideo.value =
            result.data.getCompanyExpertUserProgramProfile.introductionVideo;
          profilePicture.value =
            result.data.getCompanyExpertUserProgramProfile.user.profile_picture;
          country.value =
            result.data.getCompanyExpertUserProgramProfile.user.country;
          state.value =
            result.data.getCompanyExpertUserProgramProfile.user.state;
          industry.value =
            result.data.getCompanyExpertUserProgramProfile.user.industry;
          aboutMe.value =
            result.data.getCompanyExpertUserProgramProfile.user.about_me;
          linkedinProfile.value =
            result.data.getCompanyExpertUserProgramProfile.user.linkedin_profile;
          expertise.value =
            result.data.getCompanyExpertUserProgramProfile.expertise;
          experience.value =
            result.data.getCompanyExpertUserProgramProfile.experience;
          title.value =
            result.data.getCompanyExpertUserProgramProfile.user.title;
          companyName.value =
            result.data.getCompanyExpertUserProgramProfile.user.companyName;
        }
      });

      onErrorEntryData((error) => {
        console.log("error ", error);
        if (error) {
          errorMessage.value = error.message;
          console.log("errorMessage.value ", errorMessage.value);
        }
      });
    };

    const handleReject = () => {
      handleStatusChange("rejected");
    };

    const handleAccept = () => {
      handleStatusChange("approved");
    };

    const handleStatusChange = (status: string) => {
      changeExpertJoinProgramRequestStatus({
        input: {
          requestId: props.requestId,
          status: status,
        },
      });

      changeExpertJoinProgramRequestStatusDone((result) => {
        if (result) {
          if (result.data.handleJoinProgramRequestStatus.success) {
            closeModal();
            emit("refreshRequests");
          } else {
            errorMessage.value =
              result.data.handleJoinProgramRequestStatus.error;
          }
        }
      });

      changeExpertJoinProgramRequestStatusError((error) => {
        if (error) {
          errorMessage.value = error.message;
          console.log("errorMessage.value ", errorMessage.value);
        }
      });
    };

    const initials = () => {
      if (props.expert.firstName && props.expert.lastName) {
        const firstInitial = props.expert.firstName.charAt(0).toUpperCase();
        const lastInitial = props.expert.lastName.charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
      }
      return "";
    };

    const profilePictureUrl = computed(() => {
      if (profilePicture.value) {
        return `${profilePicture.value}?t=${new Date().getTime()}`;
      }
      return "";
    });

    onMounted(() => {
      fetchEntryData();
    });

    return {
      closeModal,
      summary,
      introductionVideo,
      profilePicture,
      country,
      state,
      industry,
      aboutMe,
      linkedinProfile,
      expertise,
      experience,
      title,
      companyName,
      programName,
      errorMessage,
      handleCanPlay,
      handleLoadStart,
      isVideoLoading,
      handleReject,
      handleAccept,
      profilePictureUrl,
      initials,
    };
  },
});
</script>
