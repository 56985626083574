<template>
  <div
    class="flex z-10 gap-8 justify-start items-center border-b border-gray-200 self-start mt-8 mx-10 max-md:ml-2.5"
  >
    <div
      class="flex flex-col justify-center self-stretch my-auto text-sm font-medium leading-none text-violet-600 whitespace-nowrap"
    >
      <div class="flex gap-2 justify-center items-center self-center px-1 pb-4">
        <div v-html="HTML_ICON" class="text-primary-purple"></div>
        <span class="self-stretch my-auto">HTML</span>
      </div>
      <div class="flex w-full bg-violet-600 min-h-[2px]"></div>
    </div>
  </div>
  <div class="flex flex-col mt-8 max-w-full mx-10">
    <div class="flex gap-5 max-md:flex-col">
      <div class="flex flex-col w-[85%] max-md:ml-0 max-md:w-full">
        <div
          class="flex flex-col grow mt-3 text-sm font-medium leading-5 text-black max-md:mt-7 max-md:max-w-full"
        >
          <pre
            ref="codeSnippet"
            class="self-start text-left overflow-x-auto p-4 rounded-md max-w-full break-all whitespace-pre-wrap bg-gray-100 border border-gray-300"
          >
<code class="language-html break-all">
# div should be placed at the bottom of the,
# outer most container of the webpage.

{{ companyWidget }}

&lt;script src="https://{{ awsBucket }}.s3.us-east-1.amazonaws.com/{{ widgetDirectory }}/bundle.js"&gt;&lt;/script&gt;
&lt;link rel="stylesheet" href="https://{{ awsBucket }}.s3.us-east-1.amazonaws.com/{{ widgetDirectory }}/bundle.css"&gt;
</code>
    </pre>
        </div>
      </div>
      <div class="flex flex-col ml-5 w-[15%] max-md:ml-0 max-md:w-full">
        <button
          @click="copyCodeToClipboard"
          class="overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 w-full text-base font-medium text-indigo-700 bg-indigo-50 rounded-md shadow-sm max-md:mt-4"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { HTML_ICON } from "../../../assets/svg/shared/svg-constants";
import { useUserStore } from "../../../stores/user";
// @/assets/svg/shared/svg-constants

export default defineComponent({
  name: "GetWidgetCode",
  setup() {
    const userStore = useUserStore();
    const codeSnippet = ref<HTMLElement | null>(null);
    const widgetDirectory = process.env.VUE_APP_WIDGET_DIRECTORY;
    const awsBucket = process.env.VUE_APP_S3_COMPANY_WIDGET_BUCKET;
    const companyWidget = ref<string>(
      `<div id="company-widget-container" data-widget-id="${
        userStore.createExpertProgram?.createdWidgetIds?.length
          ? userStore.createExpertProgram.createdWidgetIds.join(":")
          : ""
      }"></div>`
    );

    const buttonText = ref("Copy Code");

    const copyCodeToClipboard = () => {
      const codeElement = codeSnippet.value?.querySelector("code");
      const codeText = codeElement?.textContent || "";
      if (codeText) {
        navigator.clipboard
          .writeText(codeText)
          .then(() => {
            buttonText.value = "Copied!";
            setTimeout(() => {
              buttonText.value = "Copy Code";
            }, 2000);
          })
          .catch((error) => {
            console.error("Failed to copy the code: ", error);
          });
      }
    };

    return {
      codeSnippet,
      copyCodeToClipboard,
      buttonText,
      HTML_ICON,
      companyWidget,
      widgetDirectory,
      awsBucket,
    };
  },
});
</script>
