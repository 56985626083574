import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full bg-gray-50 px-4 py-2 gap-3" }
const _hoisted_2 = { class: "flex flex-wrap gap-2 mt-3 text-base font-medium text-center leading-5 tracking-tight text-gray-700" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex items-center justify-center w-8 h-8 bg-violet-100 rounded-full" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex justify-end items-center" }
const _hoisted_7 = {
  key: 0,
  class: "flex mr-2 text-primary-purple"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailsList, (emailItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "px-2 py-2 border gap-2 border-gray-300 rounded-full shadow-sm flex items-center space-x-2"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(emailItem[0]), 1)
          ]),
          _createElementVNode("span", null, _toDisplayString(emailItem.split("@")[0]), 1),
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.removeEmail(index)),
            class: "text-2xl hover:text-red-700 focus:outline-none"
          }, " × ", 8, _hoisted_5)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-in duration-300",
        "leave-active-class": "transition ease-out duration-300",
        "enter-from-class": "opacity-0",
        "enter-to-class": "opacity-100",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          (_ctx.sendInviteLoading || _ctx.sentSuccessfully)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.inviteSentMessage()), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseButton, {
        type: "button",
        onClick: _ctx.callSendInvite,
        disabled: _ctx.sendInviteLoading,
        class: "flex justify-center cursor-pointer items-center py-2 px-4 rounded-md text-sm font-medium text-white bg-primary-purple",
        style: {"width":"auto"}
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "Invite", -1)
        ])),
        _: 1
      }, 8, ["onClick", "disabled"])
    ])
  ]))
}