<template>
  <div class="flex flex-col p-4 gap-4">
    <div class="flex flex-col">
      <div class="flex flex-wrap justify-between items-center">
        <div class="flex flex-col text-left">
          <span class="text-black text-sm font-medium leading-5">Details</span>
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            class="gap-2 text-primary-purple font-inter text-base font-medium leading-6"
            @click="openEditDetails"
          >
            <div class="flex flex-row gap-2">
              <div v-html="EDIT_ICON"></div>
              Edit
            </div>
          </BaseButton>
        </div>
      </div>
    </div>
    <!-- program description -->
    <div class="flex flex-col">
      <div class="flex flex-wrap gap-2 items-center text-left">
        <span class="text-lg font-light leading-loose text-violet-600">
          <div v-html="CIRCLE_EXCLAMATION"></div>
        </span>
        <h2 class="my-auto text-sm font-medium leading-none text-zinc-800">
          About Program
        </h2>
      </div>
      <p
        class="mt-3 mr-6 text-sm leading-5 text-gray-500 text-left max-md:mr-2.5"
      >
        {{ aboutProgram }}
      </p>
    </div>
    <div class="flex flex-col">
      <hr class="shrink-0 mt-6 max-w-full h-px border border-solid" />
    </div>
    <div class="flex flex-col">
      <div class="flex flex-wrap gap-2 items-center text-left">
        <span class="text-lg font-light leading-loose text-violet-600">
          <div v-html="DOCUMENT_DOLLAR_ICON"></div>
        </span>
        <h2 class="my-auto text-sm font-medium leading-none text-zinc-800">
          Expert Thank You Payout
        </h2>
      </div>
      <p
        class="mt-3 mr-6 text-sm leading-5 text-gray-500 text-left max-md:mr-2.5"
      >
        ${{ (payout / 100).toFixed(2) }}
      </p>
    </div>
    <div class="flex flex-col">
      <hr class="shrink-0 mt-2 max-w-full h-px border border-solid" />
    </div>
    <!-- goals and use cases -->
    <div class="flex flex-col gap-4">
      <div class="flex flex-wrap gap-2 items-center text-left">
        <img
          loading="lazy"
          src="../../../../assets/shared/bullseye-icon.svg"
          alt="Bullseye icon"
          class="shrink-0 my-auto w-5 aspect-square"
        />
        <h2 class="my-auto text-sm font-medium leading-none text-zinc-800">
          Goals $ Use Cases
        </h2>
      </div>
      <div class="flex flex-col text-left gap-2">
        <div
          class="flex flex-row gap-2 items-center"
          v-if="convertWebsiteVisitors"
        >
          <div v-html="CHECK_CIRCLE" class="text-primary-purple"></div>
          <span class="text-gray-800 font-inter text-sm font-normal">
            Convert Website Visitors
          </span>
        </div>
        <div
          class="flex flex-row gap-2 items-center"
          v-if="generateIntentSignals"
        >
          <div v-html="CHECK_CIRCLE" class="text-primary-purple"></div>
          <span class="text-gray-800 font-inter text-sm font-normal">
            Generate Intent Signals
          </span>
        </div>
        <div class="flex flex-row gap-2 items-center" v-if="enableSales">
          <div v-html="CHECK_CIRCLE" class="text-primary-purple"></div>
          <span class="text-gray-800 font-inter text-sm font-normal">
            Enable Sales
          </span>
        </div>
        <div class="flex flex-row gap-2 items-center" v-if="driveConversion">
          <div v-html="CHECK_CIRCLE" class="text-primary-purple"></div>
          <span class="text-gray-800 font-inter text-sm font-normal">
            Drive Conversion
          </span>
        </div>
        <div
          class="flex flex-row gap-2 items-center"
          v-if="lateStageCustomerReferences"
        >
          <div v-html="CHECK_CIRCLE" class="text-primary-purple"></div>
          <span class="text-gray-800 font-inter text-sm font-normal">
            Late Stage Customer References
          </span>
        </div>
        <div class="flex flex-row gap-2 items-center" v-if="sdrSequenceStep">
          <div v-html="CHECK_CIRCLE" class="text-primary-purple"></div>
          <span class="text-gray-800 font-inter text-sm font-normal">
            SDR Sequence Step
          </span>
        </div>
        <div class="flex flex-row gap-2 items-center" v-if="engageNewCustomer">
          <div v-html="CHECK_CIRCLE" class="text-primary-purple"></div>
          <span class="text-gray-800 font-inter text-sm font-normal">
            Engage New Customer
          </span>
        </div>
      </div>
    </div>
    <!-- payout only when call duration more than 5 mins -->
    <div class="flex flex-col" v-if="payoutOnlyCallDurationMoreThan5">
      <div class="flex flex-col">
        <hr class="shrink-0 mt-6 max-w-full h-px border border-solid" />
      </div>
      <div class="flex flex-col mt-4">
        <div class="flex flex-wrap gap-2 items-center text-left">
          <span class="text-lg font-light leading-loose text-violet-600">
            <div v-html="CIRCLE_EXCLAMATION"></div>
          </span>
          <h2 class="my-auto text-sm font-medium leading-none text-zinc-800">
            Settings
          </h2>
        </div>
        <div class="flex flex-col gap-3">
          <p
            v-if="payoutOnlyCallDurationMoreThan5"
            class="mt-3 mr-6 text-sm leading-5 text-gray-500 text-left max-md:mr-2.5"
          >
            Meeting/call must be more than 5 minutes long to qualify for the
            thank you payout.
          </p>
        </div>
      </div>
    </div>
  </div>
  <UpdateProgramDetailsModal
    v-if="editDetailsVisible"
    :programId="userStore.selectedProgram?.id || ''"
    :isVisible="editDetailsVisible"
    :aboutProgram="aboutProgram"
    :payout="payout"
    :payoutOnlyCallDurationMoreThan5="payoutOnlyCallDurationMoreThan5"
    :convertWebsiteVisitors="convertWebsiteVisitors"
    :generateIntentSignals="generateIntentSignals"
    :enableSales="enableSales"
    :engageNewCustomer="engageNewCustomer"
    :driveConversion="driveConversion"
    :sdrSequenceStep="sdrSequenceStep"
    :lateStageCustomerReferences="lateStageCustomerReferences"
    @close="closeEditDetails"
    @programUpdated="programUpdated"
  />
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { EDIT_ICON } from "@/assets/svg/shared/svg-constants";
import BaseButton from "@/components/shared/BaseButton.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import UpdateProgramDetailsModal from "./UpdateProgramDetailsModal.vue";
import { useUserStore } from "@/stores/user";
import {
  CIRCLE_EXCLAMATION,
  CHECK_CIRCLE,
  DOCUMENT_DOLLAR_ICON,
} from "@/assets/svg/shared/svg-constants";

const GET_PROGRAM_DETAILS = gql`
  query getProgramDetail($programId: ID!) {
    getProgramDetail(programId: $programId) {
      id
      payout
      description
      convertWebsiteVisitors
      generateIntentSignals
      enableSales
      driveConversion
      lateStageCustomerReferences
      sdrSequenceStep
      engageNewCustomer
      payoutOnlyCallDurationMoreThan5
    }
  }
`;

export default defineComponent({
  name: "DetailsSection",
  components: {
    BaseButton,
    UpdateProgramDetailsModal,
  },
  setup() {
    const userStore = useUserStore();
    const editDetailsVisible = ref(false);
    const aboutProgram = ref("");
    const convertWebsiteVisitors = ref(false);
    const generateIntentSignals = ref(false);
    const enableSales = ref(false);
    const driveConversion = ref(false);
    const lateStageCustomerReferences = ref(false);
    const sdrSequenceStep = ref(false);
    const engageNewCustomer = ref(false);
    const payoutOnlyCallDurationMoreThan5 = ref(false);
    const payout = ref(0);
    const {
      result: programDetailResult,
      error: programDetailError,
      refetch: refetchProgramDetail,
    } = useQuery(GET_PROGRAM_DETAILS, {
      programId: userStore.selectedProgram?.id,
    });

    refetchProgramDetail();

    watch(
      programDetailResult,
      (newVal) => {
        if (newVal) {
          aboutProgram.value = newVal.getProgramDetail.description;
          convertWebsiteVisitors.value =
            newVal.getProgramDetail.convertWebsiteVisitors;
          generateIntentSignals.value =
            newVal.getProgramDetail.generateIntentSignals;
          enableSales.value = newVal.getProgramDetail.enableSales;
          driveConversion.value = newVal.getProgramDetail.driveConversion;
          lateStageCustomerReferences.value =
            newVal.getProgramDetail.lateStageCustomerReferences;
          sdrSequenceStep.value = newVal.getProgramDetail.sdrSequenceStep;
          engageNewCustomer.value = newVal.getProgramDetail.engageNewCustomer;
          payoutOnlyCallDurationMoreThan5.value =
            newVal.getProgramDetail.payoutOnlyCallDurationMoreThan5;
          payout.value = newVal.getProgramDetail.payout;
        }
      },
      { immediate: true }
    );

    watch(
      programDetailError,
      (newVal) => {
        if (newVal) {
          console.log("error", newVal);
        }
      },
      { immediate: true }
    );

    const programUpdated = () => {
      console.log("program updated");
      refetchProgramDetail();
      closeEditDetails();
    };

    const openEditDetails = () => {
      editDetailsVisible.value = true;
    };

    const closeEditDetails = () => {
      editDetailsVisible.value = false;
    };

    return {
      editDetailsVisible,
      closeEditDetails,
      EDIT_ICON,
      CIRCLE_EXCLAMATION,
      DOCUMENT_DOLLAR_ICON,
      aboutProgram,
      convertWebsiteVisitors,
      generateIntentSignals,
      enableSales,
      driveConversion,
      lateStageCustomerReferences,
      sdrSequenceStep,
      engageNewCustomer,
      payoutOnlyCallDurationMoreThan5,
      CHECK_CIRCLE,
      openEditDetails,
      userStore,
      programUpdated,
      payout,
    };
  },
});
</script>
