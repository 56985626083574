import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_2 = { class: "min-w-full border border-gray-300 mb-8" }
const _hoisted_3 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex justify-center px-4 py-2 border-b" }
const _hoisted_8 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_11 = { class: "flex flex-col" }
const _hoisted_12 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_15 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_16 = { class: "flex justify-center relative" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 0,
  class: "absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
}
const _hoisted_19 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_20 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_21 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b relative flex justify-center" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = {
  key: 0,
  class: "absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
}
const _hoisted_24 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ReviewEntryModal = _resolveComponent("ReviewEntryModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isRequestsLoading || _ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("table", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("thead", { class: "justify-between" }, [
        _createElementVNode("tr", { class: "border-b border-gray-300" }, [
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Name "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Email "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Total Thank You Payouts "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Status "),
          _createElementVNode("th", { class: "px-4 py-2 text-center text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Actions ")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requestsRows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: "hover:bg-gray-50 text-left"
          }, [
            _createElementVNode("td", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(row.user.firstName + " " + row.user.lastName), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(row.user.email), 1)
              ])
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }, "--", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("td", { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }, [
              _createElementVNode("span", { class: "px-2 rounded-lg text-gray-800 bg-orange-100" }, "Applied")
            ], -1)),
            _createElementVNode("td", _hoisted_7, [
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "flex justify-center text-xs items-center py-2 px-4 rounded-md bg-indigo-100 text-primary-purple",
                style: {"width":"auto"},
                onClick: ($event: any) => (_ctx.handleReviewEntry(index))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Approve ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expertsRows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: _normalizeClass(["hover:bg-gray-50 text-left", {
          'opacity-50': _ctx.shouldDisable(row.joinProgramRequest?.[0]?.status),
        }])
          }, [
            _createElementVNode("td", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", null, _toDisplayString(row.firstName) + " " + _toDisplayString(row.lastName), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", null, _toDisplayString(row.email), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", null, "$" + _toDisplayString(row.credits), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_14, [
              _createElementVNode("span", {
                class: _normalizeClass(["px-2 rounded-lg", _ctx.getClassForStatus(row.joinProgramRequest?.[0]?.status)])
              }, _toDisplayString(_ctx.status(row.joinProgramRequest?.[0]?.status)), 3)
            ]),
            _createElementVNode("td", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_BaseButton, {
                  type: "button",
                  class: "dropdown-menu",
                  style: {"width":"auto"},
                  disabled: _ctx.shouldDisable(row.joinProgramRequest?.[0]?.status),
                  onClick: ($event: any) => (_ctx.toggleDropdown(row.id))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.DOTS_VERTICAL_ICON,
                      class: "ml-3 text-gray-800"
                    }, null, 8, _hoisted_17)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                (_ctx.isDropdownOpen === row.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      (
                  _ctx.status(row.joinProgramRequest?.[0]?.status) !== 'Interested'
                )
                        ? (_openBlock(), _createBlock(_component_BaseButton, {
                            key: 0,
                            type: "button",
                            onClick: ($event: any) => (_ctx.handleDelete(index, 'expert')),
                            class: "block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.expertButtonText(row.joinProgramRequest?.[0]?.status)), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true),
                      (
                  _ctx.status(row.joinProgramRequest?.[0]?.status) === 'Interested'
                )
                        ? (_openBlock(), _createBlock(_component_BaseButton, {
                            key: 1,
                            type: "button",
                            onClick: ($event: any) => (_ctx.handleSendInvitation(index)),
                            class: "block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" Send Invite ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invitationsRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: _normalizeClass([{ 'opacity-50': row.status === 'expired' }, "hover:bg-gray-50 text-left"])
          }, [
            _cache[5] || (_cache[5] = _createElementVNode("td", { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }, " -- ", -1)),
            _createElementVNode("td", _hoisted_19, _toDisplayString(row.userEmail), 1),
            _cache[6] || (_cache[6] = _createElementVNode("td", { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }, " -- ", -1)),
            _createElementVNode("td", _hoisted_20, [
              _createElementVNode("span", {
                class: _normalizeClass(["px-2 rounded-lg", {
              'text-green-800 bg-green-100': row.status === 'accepted',
              'text-red-800 bg-red-100': row.status === 'expired',
              'text-gray-600 bg-gray-200': row.status === 'pending',
            }])
              }, _toDisplayString(_ctx.invitationStatus(row.status)), 3)
            ]),
            _createElementVNode("td", _hoisted_21, [
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "flex justify-center items-center dropdown-menu",
                style: {"width":"auto"},
                disabled: 
              _ctx.expireInvitationLoading ||
              _ctx.sendInvitationLoading ||
              row.status === 'expired' ||
              row.status === 'accepted'
            ,
                onClick: ($event: any) => (_ctx.toggleDropdown(row.id))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: _ctx.DOTS_VERTICAL_ICON,
                    class: "ml-3 text-gray-800"
                  }, null, 8, _hoisted_22)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]),
              (_ctx.isDropdownOpen === row.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.handleDelete(row.id, 'invitation')),
                      class: "block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    }, " Delete ", 8, _hoisted_24)
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2))
        }), 128))
      ])
    ]),
    (_ctx.showReviewEntryModal)
      ? (_openBlock(), _createBlock(_component_ReviewEntryModal, {
          key: 1,
          isOpen: _ctx.showReviewEntryModal,
          onClose: () => (_ctx.showReviewEntryModal = false),
          expert: _ctx.requestsRows[_ctx.selectedRequestIndex]?.user,
          requestId: _ctx.requestsRows[_ctx.selectedRequestIndex]?.id,
          onRefreshRequests: _ctx.refreshExperts
        }, null, 8, ["isOpen", "onClose", "expert", "requestId", "onRefreshRequests"]))
      : _createCommentVNode("", true)
  ], 64))
}