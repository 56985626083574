<template>
  <div class="flex flex-col gap-4 overflow-y-auto max-h-[300px]">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div v-else class="flex flex-col gap-2 items-start">
      <span class="text-gray-800 text-base font-semibold leading-6">
        Payouts history ({{ bookings.length }})
      </span>
    </div>

    <div class="overflow-x-auto w-full">
      <table class="min-w-full border border-gray-300">
        <thead class="bg-gray-150">
          <tr>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              EXPERT NAME/EMAIL
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              ATTENDEE NAME/EMAIL
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              MEETING DATE
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              PAYOUT AMOUNT
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              PAYOUT FEES
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in bookings"
            :key="index"
            class="hover:bg-gray-50 text-left"
          >
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              <div class="flex flex-col gap-1">
                <span>{{ row.expertFirstName }} {{ row.expertLastName }}</span>
                <span>{{ row.expertEmail }}</span>
              </div>
            </td>
            <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
              <div class="flex flex-col gap-1">
                <span>{{ row.attendeeName }}</span>
                <span>{{ row.attendeeEmail }}</span>
              </div>
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              {{ formatDateToLongDateString(row.meetingDate) }}
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              ${{ row.payout.toFixed(2) }}
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              ${{ row.fees.toFixed(2) }}
            </td>
          </tr>
          <tr v-if="moreRecordsAvailable">
            <td
              colspan="5"
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              <BaseButton
                @click="fetchMoreRecords()"
                class="px-4 py-1 text-gray-900 text-sm font-medium leading-5 border border-gray-300 rounded-md"
              >
                View More
              </BaseButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { BookingsPayouts } from "@/types/billing-types";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { defineComponent, onMounted, ref } from "vue";
import { BookingsPayoutsEdge } from "@/types/billing-types";
import BaseButton from "@/components/shared/BaseButton.vue";

const LIST_COMPANY_BOOKINGS = gql`
  query listCompanyBookings($first: Int!, $status: [Int!]!) {
    listCompanyBookings(first: $first, status: $status) {
      edges {
        node {
          programPayout
          fees
          date
          user {
            firstName
            lastName
            email
          }
          attendee {
            name
            email
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export default defineComponent({
  name: "CardTransactionHistory",
  components: {
    BaseButton,
  },
  props: {
    handleError: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const numberOfBookings = ref(10);
    const moreRecordsAvailable = ref(false);
    const isLoading = ref(false);
    const bookings = ref<BookingsPayouts[]>([]);

    const formatDateToLongDateString = (dateString: Date): string => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const fetchBookings = () => {
      isLoading.value = true;
      const { onResult: onQueryBookingsResult, onError: onQueryBookingsError } =
        useQuery(
          LIST_COMPANY_BOOKINGS,
          {
            first: numberOfBookings.value,
            status: [2],
          },
          {
            fetchPolicy: "network-only",
          }
        );

      onQueryBookingsResult((result) => {
        if (result) {
          if ((result?.errors?.length ?? 0) > 0) {
            props.handleError("payouts history");
          } else if (result.data?.listCompanyBookings?.edges?.length > 0) {
            bookings.value = result.data.listCompanyBookings.edges.map(
              (edge: BookingsPayoutsEdge) => ({
                meetingDate: edge.node?.date,
                expertFirstName: edge.node?.user?.firstName,
                expertLastName: edge.node?.user?.lastName,
                expertEmail: edge.node?.user?.email,
                attendeeName: edge.node?.attendee?.name,
                attendeeEmail: edge.node?.attendee?.email,
                payout: edge.node?.programPayout,
                fees: edge.node?.fees,
              })
            );

            moreRecordsAvailable.value =
              result.data.listCompanyBookings.pageInfo.hasNextPage;
          }
          isLoading.value = false;
        }
      });

      onQueryBookingsError((error) => {
        if (error) {
          props.handleError("payouts history");
          isLoading.value = false;
        }
      });
    };

    const fetchMoreRecords = () => {
      numberOfBookings.value += 5;
      fetchBookings();
    };

    onMounted(() => {
      fetchBookings();
    });

    return {
      formatDateToLongDateString,
      bookings,
      moreRecordsAvailable,
      fetchMoreRecords,
      isLoading,
    };
  },
});
</script>
