import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 overflow-y-auto max-h-[300px]" }
const _hoisted_2 = { class: "flex flex-col gap-2 items-start" }
const _hoisted_3 = { class: "text-gray-800 text-base font-semibold leading-6" }
const _hoisted_4 = { class: "overflow-x-auto w-full" }
const _hoisted_5 = { class: "min-w-full border border-gray-300" }
const _hoisted_6 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_7 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_8 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_9 = { class: "px-4 py-2 border-b" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  colspan: "5",
  class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, " Invoice history (" + _toDisplayString(_ctx.rows.length) + ") ", 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("table", _hoisted_5, [
        _cache[3] || (_cache[3] = _createElementVNode("thead", { class: "bg-gray-150" }, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Invoice Number "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Due Date "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Status "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Action ")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row.invoiceNumber,
              class: "hover:bg-gray-50 text-left"
            }, [
              _createElementVNode("td", _hoisted_6, _toDisplayString(row.invoiceNumber), 1),
              _createElementVNode("td", _hoisted_7, _toDisplayString(row.dueDate), 1),
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("span", {
                  class: _normalizeClass(["px-2 rounded-lg", {
                  'text-green-800 bg-green-100': row.status === 'paid',
                  'text-red-800 bg-red-100': row.status === 'pending',
                }])
                }, _toDisplayString(row.status.toUpperCase()), 3)
              ]),
              _createElementVNode("td", _hoisted_9, [
                _createVNode(_component_BaseButton, {
                  onClick: ($event: any) => (_ctx.viewInvoice(index)),
                  class: "px-4 py-1 text-gray-900 text-sm font-medium leading-5 border border-gray-300 rounded-md"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" View ")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]))
          }), 128)),
          (_ctx.hasMoreInvoices)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                _createElementVNode("td", _hoisted_11, [
                  _createVNode(_component_BaseButton, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchMoreInvoices())),
                    class: "px-4 py-1 text-gray-900 text-sm font-medium leading-5 border border-gray-300 rounded-md"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" View More ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}