<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <header class="flex flex-col w-full border-b">
      <Navbar :activeTab="0" />
    </header>
    <div class="flex flex-col gap-6 mb-16 p-6">
      <div
        v-if="isLoadingAccountBalance"
        class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      >
        <!-- Spinner -->
        <div
          class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
        ></div>
      </div>
      <div class="flex flex-col items-start">
        <span class="text-black text-3xl font-semibold leading-9">Billing</span>
      </div>
      <!-- error message -->
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <img
            src="../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
      <!-- available balance  -->
      <div class="flex flex-col gap-6" v-show="showCurrentAccountBalance">
        <CurrentAccountBalance
          :availableBalance="availableBalance"
          :overdrawnBalance="overdrawnBalance"
          :pendingBalance="pendingBalance"
          :autoTopup="autoTopup"
          :autoRechargeAmount="autoRechargeAmount"
          :lowBalanceAmount="lowBalanceAmount"
          :paymentMethodCardNumber="paymentMethodCardNumber"
          :paymentMethodCreatedAt="paymentMethodCreatedAt"
          :paymentMethodSource="paymentMethodSource"
          :paymentMethodInvoiceRecipientEmail="
            paymentMethodInvoiceRecipientEmail || ''
          "
          :updateAccountBalance="updateAccountBalance"
        />
        <div class="flex flex-col gap-4 border border-gray-200 rounded-lg p-4">
          <div class="flex flex-col">
            <div
              class="flex flex-row max-w-max gap-2 bg-gray-200 rounded-lg p-2"
            >
              <BaseButton
                @click="showBillingHistory()"
                class="px-4 py-1 text-black text-sm font-medium leading-5"
                :class="
                  isPayoutHistoryVisible
                    ? 'bg-gray-200'
                    : 'bg-gray-100 rounded-md'
                "
              >
                Billing History
              </BaseButton>
              <BaseButton
                @click="showPayoutHistory()"
                class="px-4 py-1 text-gray-900 text-sm font-medium leading-5"
                :class="
                  isPayoutHistoryVisible
                    ? 'bg-gray-100 rounded-md'
                    : 'bg-gray-200'
                "
              >
                Payout History
              </BaseButton>
            </div>
          </div>
          <PayoutsHistory
            v-if="isPayoutHistoryVisible"
            :handleError="handleError"
          />
          <InvoiceHistory
            v-if="!isPayoutHistoryVisible"
            ref="invoiceHistoryRef"
            :handleError="handleError"
          />
          <CardTransactionHistory
            v-if="!isPayoutHistoryVisible"
            :cardTransactions="cardTransactions"
          />
        </div>
      </div>
      <UpdateAccountBalance
        v-show="!showCurrentAccountBalance"
        :accountBalanceId="accountBalanceId"
        :availableBalance="availableBalance"
        :autoTopup="autoTopup"
        :autoRechargeAmount="autoRechargeAmount"
        :lowBalanceAmount="lowBalanceAmount"
        :paymentMethodExpirationDate="paymentMethodExpirationDate"
        :paymentMethodCardNumber="paymentMethodCardNumber"
        :paymentMethodInvoiceRecipientEmail="paymentMethodInvoiceRecipientEmail"
        :paymentMethodSource="paymentMethodSource"
        :paymentMethodId="paymentMethodId"
        :cancel="cancelUpdateAccountBalance"
        @refetchAccountBalance="refetchAccountBalance"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import CardTransactionHistory from "@/components/billing/card/CardTransactionHistory.vue";
import {
  AccountBalance,
  ActivePaymentMethod,
  CardTransaction,
} from "@/types/dashboard-types";
import CurrentAccountBalance from "@/components/billing/CurrentAccountBalance.vue";
import UpdateAccountBalance from "@/components/billing/UpdateAccountBalance.vue";
import InvoiceHistory from "@/components/billing/invoice/InvoiceHistory.vue";
import PayoutsHistory from "@/components/billing/PayoutsHistory.vue";
import Navbar from "@/components/dashboard/Navbar.vue";
import BaseButton from "@/components/shared/BaseButton.vue";

const GET_ACCOUNT_BALANCE = gql`
  query {
    fetchAccountBalance {
      id
      availableBalance
      overdrawnBalance
      pendingBalance
      autoTopup
      autoRechargeAmount
      lowBalanceAmount
      cardTransactions {
        id
        amount
        status
        createdAt
        cardCharged {
          lastFour
          expMonth
          expYear
        }
      }
      activePaymentMethod {
        paymentMethodId
        source
        isActive
        invoiceRecipientEmail
        createdAt
        paymentInfo {
          cardNumber
          expirationDate
        }
      }
    }
  }
`;

export default defineComponent({
  name: "BillingContainer",
  components: {
    CurrentAccountBalance,
    UpdateAccountBalance,
    InvoiceHistory,
    CardTransactionHistory,
    PayoutsHistory,
    Navbar,
    BaseButton,
  },
  setup() {
    const availableBalance = ref(0);
    const overdrawnBalance = ref(0.0);
    const pendingBalance = ref(0);
    const autoTopup = ref(false);
    const autoRechargeAmount = ref(0);
    const lowBalanceAmount = ref(0);
    const paymentMethodSource = ref("");
    const paymentMethodCreatedAt = ref("");
    const paymentMethodCardNumber = ref("");
    const paymentMethodExpirationDate = ref("");
    const paymentMethodInvoiceRecipientEmail = ref("");
    const cardTransactions = ref<CardTransaction[]>([]);
    const showCurrentAccountBalance = ref(true);
    const paymentMethodId = ref("");
    const accountBalanceId = ref("");
    const isLoadingAccountBalance = ref(false);
    const errorMessage = ref("");
    const isPayoutHistoryVisible = ref(false);
    const invoiceHistoryRef = ref<InstanceType<typeof InvoiceHistory> | null>(
      null
    );

    onMounted(() => {
      getAccountBalance();
    });

    const getAccountBalance = () => {
      const {
        result: queryResult,
        error: queryError,
        refetch: refetchAccountBalance,
      } = useQuery(GET_ACCOUNT_BALANCE);

      refetchAccountBalance();

      isLoadingAccountBalance.value = true;

      watch(
        () => queryResult.value,
        (newValue) => {
          if (newValue) {
            isLoadingAccountBalance.value = false;
          }
          if (newValue?.fetchAccountBalance) {
            handleAccountBalance(newValue.fetchAccountBalance);
          }
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            handleError("billing");
            isLoadingAccountBalance.value = false;
          }
        },
        { immediate: true }
      );
    };

    const handleAccountBalance = (accountBalance: AccountBalance) => {
      accountBalanceId.value = accountBalance.id;
      cardTransactions.value = accountBalance.cardTransactions;

      updateBalances(accountBalance);
      updateAutoTopup(accountBalance);
      if (accountBalance.activePaymentMethod.isActive) {
        updatePaymentMethod(accountBalance.activePaymentMethod);
      }
    };

    const updateBalances = (accountBalance: AccountBalance) => {
      availableBalance.value = accountBalance.availableBalance;
      pendingBalance.value = accountBalance.pendingBalance;
      overdrawnBalance.value = accountBalance.overdrawnBalance;
    };

    const updateAutoTopup = (accountBalance: AccountBalance) => {
      autoTopup.value = accountBalance.autoTopup;
      autoRechargeAmount.value = accountBalance.autoRechargeAmount;
      lowBalanceAmount.value = accountBalance.lowBalanceAmount;
    };

    const updatePaymentMethod = (activePaymentMethod: ActivePaymentMethod) => {
      paymentMethodSource.value = activePaymentMethod.source;
      if (activePaymentMethod.source === "credit_card") {
        paymentMethodCardNumber.value =
          activePaymentMethod.paymentInfo.cardNumber;
        paymentMethodExpirationDate.value =
          activePaymentMethod.paymentInfo.expirationDate;
      }
      paymentMethodCreatedAt.value = formatDateToLongDateString(
        activePaymentMethod.createdAt
      );
      paymentMethodInvoiceRecipientEmail.value =
        activePaymentMethod.invoiceRecipientEmail;
      paymentMethodId.value = activePaymentMethod.paymentMethodId;
    };

    const formatDateToLongDateString = (dateString: Date): string => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const updateAccountBalance = () => {
      console.log("Update Account Balance clicked");
      showCurrentAccountBalance.value = false;
    };

    const cancelUpdateAccountBalance = () => {
      showCurrentAccountBalance.value = true;
    };

    const refetchAccountBalance = () => {
      getAccountBalance();
      showCurrentAccountBalance.value = true;
      invoiceHistoryRef.value?.fetchInvoices();
    };

    const showBillingHistory = () => {
      isPayoutHistoryVisible.value = false;
    };

    const showPayoutHistory = () => {
      isPayoutHistoryVisible.value = true;
    };

    const handleError = (errorType: string) => {
      errorMessage.value = `Error fetching ${errorType} details`;
      setTimeout(() => {
        errorMessage.value = "";
      }, 5000);
    };

    return {
      availableBalance,
      overdrawnBalance,
      pendingBalance,
      autoTopup,
      autoRechargeAmount,
      lowBalanceAmount,
      paymentMethodSource,
      paymentMethodCreatedAt,
      paymentMethodCardNumber,
      paymentMethodInvoiceRecipientEmail,
      paymentMethodExpirationDate,
      updateAccountBalance,
      showCurrentAccountBalance,
      cancelUpdateAccountBalance,
      paymentMethodId,
      accountBalanceId,
      refetchAccountBalance,
      isLoadingAccountBalance,
      cardTransactions,
      errorMessage,
      handleError,
      showBillingHistory,
      showPayoutHistory,
      isPayoutHistoryVisible,
      invoiceHistoryRef,
    };
  },
});
</script>
